import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase/firebase";

import { centroPoblados } from "../data/centroPoblados";

const Edit = () => {
  // * I. DATOS GENERALES DEL PRODUCTOR
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [centropoblado, setCentropoblado] = useState("");
  const [chestudian, setChestudian] = useState("");
  const [chijos, setChijos] = useState("");
  const [comunidadsector, setComunidadsector] = useState([]);
  const [dni, setDni] = useState("");
  const [edad, setEdad] = useState("0");
  const [genero, setGenero] = useState("");
  const [ncelular, setNcelular] = useState("");
  const [niveducacion, setNiveducacion] = useState("");
  const [observaciones, setObservaciones] = useState("");

  // 1.2. Datos economicas
  const [actividadagropecuaria, setActividadagropecuaria] = useState(null);
  const [actividadconstruccion, setActividadconstruccion] = useState(null);
  const [
    actividadtransportescomunidaciones,
    setActividadtransportescomunidaciones,
  ] = useState(null);
  const [actividadmineria, setActividadmineria] = useState(null);
  const [actividadcomercio, setActividadcomercio] = useState(null);
  const [actividadotros, setActividadotros] = useState(null);

  // INGRESO TOTAL
  const [ingresototal, setIngresototal] = useState("");

  // SERVICIOS BASICOS
  const [servicioagua, setServicioagua] = useState(null);
  const [energiaelectrica, setEnergiaelectrica] = useState(null);
  const [energiatrifasica, setEnergiatrifasica] = useState(null);
  const [internet, setInternet] = useState(null);

  // Acceso vial
  const [accesovial, setAccesovial] = useState("");

  //* TODO: TIPO DE MATERIAL DE CONSTRUCION DE LA VIVIENDA
  const [tipodematerialdeconstruccion, setTipodematerialdeconstruccion] =
    useState("");

  //* 2: II. PISO FORRAJERO
  const [totaldehectareas, setTotaldehectareas] = useState("");
  const [areapasiva, setAreapasiva] = useState("");
  const [areaactiva, setAreaactiva] = useState("");
  const [cantidaddealfalfa, setCantidaddealfalfa] = useState("");
  const [cantidadderyegrass, setCantidadderyegrass] = useState("");
  const [cantidaddeavenaforrajera, setCantidaddeavenaforrajera] = useState("");
  const [cantidaddecebadaforrajera, setCantidaddecebadaforrajera] =
    useState("");
  const [cantidaddepastonatural, setCantidaddepastonatural] = useState("");
  const [cantidaddalfalfa, setCantidaddalfalfa] = useState("");
  const [cultivopapa, setCultivopapa] = useState("");
  const [cultivoquinua, setCultivoquinua] = useState("");
  const [cultivocaniua, setCultivocaniua] = useState("");
  const [cultivohabas, setCultivohabas] = useState("");
  const [cultivootros, setCultivootros] = useState("");

  //* 3: III. ACESO AL RIEGO
  // 3.1. ¿tiene riego?
  const [tieneriego, setTieneriego] = useState("");

  // "riego-que-utiliza": riegoutiliza,
  const [fuentedeagua, setFuentedeagua] = useState("");

  // 3.4. ¿Considera usted que el agua de riego esta contaminada?
  const [aguaderiegocontaminada, setAguaderiegocontaminada] = useState("");
  const [relaveminero, setRelaveminero] = useState(null);
  const [desagueindustrial, setDesagueindustrial] = useState(null);
  const [otrassustancias, setOtrassustancias] = useState(null);

  // * 4: IV. INFRAESTRUCTURA AGROPECUARIA QUE CUENTA EN PRODUCTOR
  const [cobertizos, setCobertizos] = useState(null);
  const [miniestablos, setMiniestablos] = useState(null);
  const [comedero, setComedero] = useState(null);
  const [bebederos, setBebederos] = useState(null);
  const [terneraje, setTerneraje] = useState(null);
  const [salaordenio, setSalaordenio] = useState(null);
  const [plataformadeordenio, setPlataformadeordenio] = useState(null);
  const [invernadero, setInvernadero] = useState(null);
  const [galponcuyes, setGalponcuyes] = useState(null);
  const [silosforraje, setSilosforraje] = useState(null);
  const [cercoelectrico, setCercoelectrico] = useState(null);
  const [cercotapial, setCercotapial] = useState(null);
  const [cercopiedra, setCercopiedra] = useState(null);
  const [establolechero, setEstablolechero] = useState(null);

  //* 5: V. MAQUINARIAS, EQUIPOS Y HERRAMIENTAS QUE DISPONE LA UNIDAD PRODUCTIVA O NUCLEO FAMILIAR
  const [tractoragricola, setTractoragricola] = useState(null);
  const [impleagricola, setImpleagricola] = useState(null);
  const [ordeniadora, setOrdeniadora] = useState(null);
  const [molineraforraje, setMolineraforraje] = useState(null);
  const [motoguadania, setMotoguadania] = useState(null);
  const [motocarga, setMotocarga] = useState(null);
  const [porongo, setPorongo] = useState(null);
  const [picadoraforraje, setPicadoraforraje] = useState(null);
  const [otrosmaquinarias, setOtrosmaquinarias] = useState(null);

  // * 6: VI. ACCESO A FINANCIAMIENTO DEL PRODUCTOR
  const [tienecreditofinanciamiento, setTienecreditofinanciamiento] =
    useState("");
  const [tienecreditocajamunicial, setTienecreditocajamunicial] =
    useState(null);
  const [tienecreditocajarural, setTienecreditocajarural] = useState(null);
  const [tienecreditocooperativaahorro, setTienecreditocooperativaahorro] =
    useState(null);
  const [tienecreditobanco, setTienecreditobanco] = useState(null);
  const [tienecreditoagrobanco, setTienecreditoagrobanco] = useState(null);
  const [tienecreditoong, setTienecreditoong] = useState(null);
  const [tienecreditootrosfondos, setTienecreditootrosfondos] = useState(null);

  // 6.2. ¿PARA QUE SOLICITO EL CREDITO?
  const [solicitocreditoadquisemilla, setSolicitocreditoadquisemilla] =
    useState(null);
  const [solicitocreditoadquimaquinarias, setSolicitocreditoadquimaquinarias] =
    useState(null);
  const [solicitocreditoadquiganadoovino, setSolicitocreditoadquiganadoovino] =
    useState(null);
  const [
    solicitocreditoadquiganadovacuno,
    setSolicitocreditoadquiganadovacuno,
  ] = useState(null);
  const [solicitocreditopagarotrocredito, setSolicitocreditopagarotrocredito] =
    useState(null);
  const [solicitocreditopagarotros, setSolicitocreditopagarotros] =
    useState(null);

  //* 7: VII. RECIBE FINANCIMIENTO DE PROYECTOS Y/O PLAN DE NEGOCIO POR OTRAS ENTIDADES PUBLICAS O PRIVADAS.
  const [accesofinanciamiento, setAccesofinanciamiento] = useState("");
  const [accesofinandistrital, setAccesofinandistrital] = useState(null);
  const [accesofinanproregional, setAccesofinanproregional] = useState(null);
  const [accedofinanpromunicipal, setAccedofinanpromunicipal] = useState(null);
  const [accedofinanfondoempleo, setAccedofinanfondoempleo] = useState(null);
  const [accedofinancoopinternacional, setAccedofinancoopinternacional] =
    useState(null);
  const [accedofinanagroideas, setAccedofinanagroideas] = useState(null);
  const [accedofinanagrorural, setAccedofinanagrorural] = useState(null);
  const [accedofinanfoncodes, setAccedofinanfoncodes] = useState(null);
  const [accedofinanotros, setAccedofinanotros] = useState(null);

  // * 8: VIII. CARACTERISTICAS DEL GANADO VACUNO
  // 8.1. Nº total de vacunos
  const [ntotalvacunobrownswiss, setNtotalvacunobrownswiss] = useState("");
  const [ntotalvacunocriollo, setNtotalvacunocriollo] = useState("");
  const [ntotalvacunoholstein, setNtotalvacunoholstein] = useState("");
  const [ntotalvacunootraraza, setNtotalvacunootraraza] = useState("");
  // 8.2. Total vacas
  const [totvacasbrownswiss, setTotvacasbrownswiss] = useState("");
  const [totvacascriollo, setTotvacascriollo] = useState("");
  const [totvacasholstein, setTotvacasholstein] = useState("");
  const [totvacasotraraza, setTotvacasotraraza] = useState("");
  // 8.3. Total Toros
  const [tottorosbrownswiss, setTottorosbrownswiss] = useState("");
  const [tottoroscriollo, setTottoroscriollo] = useState("");
  const [tottorosholstein, setTottorosholstein] = useState("");
  const [tottorosotraraza, setTottorosotraraza] = useState("");
  // 8.4. Vacas en produccion
  const [vacasprodbrownswiss, setVacasprodbrownswiss] = useState("");
  const [vacasprodcriollo, setVacasprodcriollo] = useState("");
  const [vacasprodholstein, setVacasprodholstein] = useState("");
  const [vacasprodotraraza, setVacasprodotraraza] = useState("");
  // 8.5. Terneras(menores a 1año )
  const [ternerasbrownswiss, setTernerasbrownswiss] = useState("");
  const [ternerascriollo, setTernerascriollo] = useState("");
  const [ternerasholstein, setTernerasholstein] = useState("");
  const [ternerasotraraza, setTernerasotraraza] = useState("");
  // 8.6. Terneros(menores a 1año )
  const [ternerobrownswiss, setTernerobrownswiss] = useState("");
  const [ternerocriollo, setTernerocriollo] = useState("");
  const [terneroholstein, setTerneroholstein] = useState("");
  const [ternerootraraza, setTernerootraraza] = useState("");
  // 8.7. Vaquillas (entre 1 a 2 años )
  const [vaquillasbrownswiss, setVaquillasbrownswiss] = useState("");
  const [vaquillascriollo, setVaquillascriollo] = useState("");
  const [vaquillasholstein, setVaquillasholstein] = useState("");
  const [vaquillasotraraza, setVaquillasotraraza] = useState("");
  // 8.8.Vaquillonas (primer parto)
  const [vaquillonabrownswiss, setVaquillonabrownswiss] = useState("");
  const [vaquillonacriollo, setVaquillonacriollo] = useState("");
  const [vaquillonaholstein, setVaquillonaholstein] = useState("");
  const [vaquillonaotraraza, setVaquillonaotraraza] = useState("");
  // 8.9. Vacas de engorde
  const [vacasengordebrownswiss, setVacasengordebrownswiss] = useState("");
  const [vacasengordecriollo, setVacasengordecriollo] = useState("");
  const [vacasengordeholstein, setVacasengordeholstein] = useState("");
  const [vacasengordeotraraza, setVacasengordeotraraza] = useState("");
  // 8.10. Venta de toros engorde
  const [toretesbrownswiss, setToretesbrownswiss] = useState("");
  const [toretescriollo, setToretescriollo] = useState("");
  const [toretesholstein, setToretesholstein] = useState("");
  const [toretesotraraza, setToretesotraraza] = useState("");
  // 8.11.¿Cuántos litros por dia produce la leche?
  const [litrosepocasecadia, setLitrosepocasecadia] = useState("");
  const [litroepocalluviadia, setLitroepocalluviadia] = useState("");
  // 8.12.¿Cuántas vacas tiene en produción?
  const [vacasproduccionepocaseca, setVacasproduccionepocaseca] = useState("");
  const [vacasproduccionepocalluvia, setVacasproduccionepocalluvia] =
    useState("");
  // 8.13. ¿Cuál es el destino de su producion de leche?
  const [ventalechepublicomercado, setVentalechepublicomercado] =
    useState(null);
  const [ventalechepublicomercadocosto, setVentalechepublicomercadocosto] =
    useState("");
  const [ventalecheplantaquesera, setVentalecheplantaquesera] = useState(null);
  const [ventalecheplantaqueseracosto, setVentalecheplantaqueseracosto] =
    useState("");
  const [ventalechegloria, setVentalechegloria] = useState(null);
  const [ventalechegloriacosto, setVentalechegloriacosto] = useState("");
  const [ventalecheautonconsumo, setVentalecheautonconsumo] = useState(null);
  const [ventalecheautoinsumo, setVentalecheautoinsumo] = useState(null);
  const [ventatoros, setVentatoros] = useState(null);
  const [ventatoroscosto, setVentatoroscosto] = useState("");

  //* 9: XI. CONDICIONES DE ORDEÑO
  const [ordeniorealiza, setOrdeniorealiza] = useState("");
  const [lecheenvasado, setLecheenvasado] = useState("");
  const [almacenamientoleche, setAlmacenamientoleche] = useState("");

  //* 10: XI.TIPO DE REPRODUCION
  const [tiporeproduccion, setTiporeproduccion] = useState("");
  const [tipoinseminador, setTipoinseminador] = useState("");
  const [quieninsemina, setQuieninsemina] = useState("");

  //* 11: XI. CATEGORIAS DE GANADO OVINO
  // 11.1.Nº total de ovinos
  const [totovinocorriedale, setTotovinocorriedale] = useState("");
  const [totovinohampshire, setTotovinohampshire] = useState("");
  const [totovinocriollo, setTotovinocriollo] = useState("");
  const [totovinoBlackbelly, setTotovinoBlackbelly] = useState("");
  const [totovinootras, setTotovinootras] = useState("");
  // 11.2. Nro. de Machos
  const [machocorriedale, setMachocorriedale] = useState("");
  const [machohampshire, setMachohampshire] = useState("");
  const [machocriollo, setMachocriollo] = useState("");
  const [machoBlackbelly, setMachoBlackbelly] = useState("");
  const [machootras, setMachootras] = useState("");
  // 11.3. Nro. de Hembras
  const [hembracorriedale, setHembracorriedale] = useState("");
  const [hembrahampshire, setHembrahampshire] = useState("");
  const [hembracriollo, setHembracriollo] = useState("");
  const [hembraBlackbelly, setHembraBlackbelly] = useState("");
  const [hembraotras, setHembraotras] = useState("");
  // 11.4.¿Cuál es el destino comercial de sus ovinos?
  const [ventaovinomercadopublico, setVentaovinomercadopublico] =
    useState(null);
  const [ventaovinomercadopublicocosto, setVentaovinomercadopublicocosto] =
    useState("");
  const [ventacarnicero, setVentacarnicero] = useState(null);
  const [ventacarnicerocosto, setVentacarnicerocosto] = useState("");
  const [ventaovinootros, setVentaovinootros] = useState(null);
  const [ventaovinootroscosto, setVentaovinootroscosto] = useState("");
  const [ventaovinoauto, setVentaovinoauto] = useState(null);

  //* 12: XII. ¿QUE ANIMALES MENORES CRIA UD.?
  const [menorespolloengorde, setMenorespolloengorde] = useState("");
  const [menoresgallina, setMenoresgallina] = useState("");
  const [menoresporcinos, setMenoresporcinos] = useState("");
  const [menoresllamas, setMenoresllamas] = useState("");
  const [menoresalpaca, setMenoresalpaca] = useState("");
  const [menorescaprinos, setMenorescaprinos] = useState("");
  const [menorescuyes, setMenorescuyes] = useState("");
  // const [menorescanes, setMenorescanes] = useState("");

  // * 13: XII.CAPACITACION
  const [perteneceasociacioncooperativa, setPerteneceasociacioncooperativa] =
    useState("");
  const [cualasociacioncooperativa, setCualasociacioncooperativa] =
    useState("");
  const [institucioncapacita, setInstitucioncapacita] = useState("");

  //* ¿Qué factores retrazan al productor agropecuario?
  //?

  //
  // const [latutud, setLatutud] = useState("");
  // const [longitud, setLongitud] = useState("");

  //image
  // const [image, setImage] = useState("");
  // const [nombredelfocalizador, setNombredelfocalizador] = useState("");
  // const [timestamp, Date] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   setActividadagropecuaria(value === "true");
  // };

  const update = async (event) => {
    event.preventDefault();
    const productor = doc(db, "basedsaman", id);

    const dniString = dni.toString();
    const edadNumber = parseInt(edad, 10);
    const comunidadsectorString = comunidadsector.join(", ");

    //* 2: II. PISO FORRAJERO --- Number
    const totaldehectareasNumber = parseFloat(totaldehectareas);
    const areapasivaNumber = parseFloat(areapasiva);
    const areaactivaNumber = parseFloat(areaactiva);
    const cantidaddealfalfaNumber = parseFloat(cantidaddealfalfa);
    const cantidadderyegrassNumber = parseFloat(cantidadderyegrass);
    const cantidaddeavenaforrajeraNumber = parseFloat(cantidaddeavenaforrajera);
    const cantidaddecebadaforrajeraNumber = parseFloat(
      cantidaddecebadaforrajera
    );
    const cantidaddepastonaturalNumber = parseFloat(cantidaddepastonatural);
    const cantidaddalfalfaNumber = parseFloat(cantidaddalfalfa);
    const cultivopapaNumber = parseFloat(cultivopapa);
    const cultivoquinuaNumber = parseFloat(cultivoquinua);
    const cultivocaniuaNumber = parseFloat(cultivocaniua);
    const cultivohabasNumber = parseFloat(cultivohabas);
    const cultivootrosNumber = parseFloat(cultivootros);

    const data = {
      nombre: nombre,
      apellido: apellido,
      dni: dniString,
      centropoblado: centropoblado,
      comunidadsector: comunidadsectorString,
      niveducacion: niveducacion,
      genero: genero,
      edad: edadNumber,
      ncelular: ncelular,
      chijos: chijos,
      chestudian: chestudian,
      observaciones: observaciones,

      //2
      actividadagropecuaria: actividadagropecuaria,
      actividadconstruccion: actividadconstruccion,
      actividadtransportescomunidaciones: actividadtransportescomunidaciones,
      actividadmineria: actividadmineria,
      actividadcomercio: actividadcomercio,
      actividadotros: actividadotros,
      ingresototal: ingresototal,
      servicioagua: servicioagua,
      energiaelectrica: energiaelectrica,
      energiatrifasica: energiatrifasica,
      internet: internet,
      tipodematerialdeconstruccion: tipodematerialdeconstruccion,

      accesovial: accesovial,

      //3 piso forrajero
      totaldehectareas: totaldehectareasNumber,
      areapasiva: areapasivaNumber,
      areaactiva: areaactivaNumber,
      cantidaddealfalfa: cantidaddealfalfaNumber,
      cantidadderyegrass: cantidadderyegrassNumber,
      cantidaddeavenaforrajera: cantidaddeavenaforrajeraNumber,
      cantidaddecebadaforrajera: cantidaddecebadaforrajeraNumber,
      cantidaddepastonatural: cantidaddepastonaturalNumber,
      cantidaddalfalfa: cantidaddalfalfaNumber,
      cultivopapa: cultivopapaNumber,
      cultivoquinua: cultivoquinuaNumber,
      cultivocaniua: cultivocaniuaNumber,
      cultivohabas: cultivohabasNumber,
      cultivootros: cultivootrosNumber,
      //4
      tieneriego: tieneriego,
      // "riego-que-utiliza": riegoutiliza,
      fuentedeagua: fuentedeagua,
      aguaderiegocontaminada: aguaderiegocontaminada,
      relaveminero: relaveminero,
      desagueindustrial: desagueindustrial,
      otrassustancias: otrassustancias,
      //"pertenece-comision-regantes" : comisionregantessi,
      // "cual-comision-regante" : comisioncual,
      //5
      cobertizos: cobertizos,
      miniestablos: miniestablos,
      comedero: comedero,
      bebederos: bebederos,
      terneraje: terneraje,
      salaordenio: salaordenio,
      plataformadeordenio: plataformadeordenio,
      invernadero: invernadero,
      galponcuyes: galponcuyes,
      silosforraje: silosforraje,
      // "cercoalambrado": cercoalambrado,
      // "cuantos-cerco-alambrado" : cercoalambradocuanto,
      cercoelectrico: cercoelectrico,
      cercotapial: cercotapial,
      cercopiedra: cercopiedra,
      establolechero: establolechero,
      //6
      tractoragricola: tractoragricola,
      impleagricola: impleagricola,
      ordeniadora: ordeniadora,
      molineraforraje: molineraforraje,
      motoguadania: motoguadania,
      motocarga: motocarga,
      porongo: porongo,
      picadoraforraje: picadoraforraje,
      otrosmaquinarias: otrosmaquinarias,
      //7 acceso a financiamiento
      tienecreditofinanciamiento: tienecreditofinanciamiento,
      tienecreditocajamunicial: tienecreditocajamunicial,
      tienecreditocajarural: tienecreditocajarural,
      tienecreditocooperativaahorro: tienecreditocooperativaahorro,
      tienecreditobanco: tienecreditobanco,
      tienecreditoagrobanco: tienecreditoagrobanco,
      tienecreditoong: tienecreditoong,
      tienecreditootrosfondos: tienecreditootrosfondos,

      solicitocreditoadquisemilla: solicitocreditoadquisemilla,
      solicitocreditoadquimaquinarias: solicitocreditoadquimaquinarias,
      solicitocreditoadquiganadoovino: solicitocreditoadquiganadoovino,
      solicitocreditoadquiganadovacuno: solicitocreditoadquiganadovacuno,
      solicitocreditopagarotrocredito: solicitocreditopagarotrocredito,
      solicitocreditopagarotros: solicitocreditopagarotros,

      //
      accesofinanciamiento: accesofinanciamiento,
      accesofinandistrital: accesofinandistrital,
      accesofinanproregional: accesofinanproregional,
      accedofinanpromunicipal: accedofinanpromunicipal,
      accedofinanfondoempleo: accedofinanfondoempleo,
      accedofinancoopinternacional: accedofinancoopinternacional,
      accedofinanagroideas: accedofinanagroideas,
      accedofinanagrorural: accedofinanagrorural,
      accedofinanfoncodes: accedofinanfoncodes,
      accedofinanotros: accedofinanotros,
      // "a"
      // "acceco-financiamiento-nombre": accesofinanciamientonombre,
      //9 caracteristicas del ganado
      ntotalvacunobrownswiss: ntotalvacunobrownswiss,
      ntotalvacunocriollo: ntotalvacunocriollo,
      ntotalvacunoholstein: ntotalvacunoholstein,
      ntotalvacunootraraza: ntotalvacunootraraza,

      totvacasbrownswiss: totvacasbrownswiss,
      totvacascriollo: totvacascriollo,
      totvacasholstein: totvacasholstein,
      totvacasotraraza: totvacasotraraza,

      tottorosbrownswiss: tottorosbrownswiss,
      tottoroscriollo: tottoroscriollo,
      tottorosholstein: tottorosholstein,
      tottorosotraraza: tottorosotraraza,

      vacasprodbrownswiss: vacasprodbrownswiss,
      vacasprodcriollo: vacasprodcriollo,
      vacasprodholstein: vacasprodholstein,
      vacasprodotraraza: vacasprodotraraza,

      ternerasbrownswiss: ternerasbrownswiss,
      ternerascriollo: ternerascriollo,
      ternerasholstein: ternerasholstein,
      ternerasotraraza: ternerasotraraza,

      ternerobrownswiss: ternerobrownswiss,
      ternerocriollo: ternerocriollo,
      terneroholstein: terneroholstein,
      ternerootraraza: ternerootraraza,

      vaquillasbrownswiss: vaquillasbrownswiss,
      vaquillascriollo: vaquillascriollo,
      vaquillasholstein: vaquillasholstein,
      vaquillasotraraza: vaquillasotraraza,
      vaquillonabrownswiss: vaquillonabrownswiss,
      vaquillonacriollo: vaquillonacriollo,
      vaquillonaholstein: vaquillonaholstein,
      vaquillonaotraraza: vaquillonaotraraza,

      vacasengordebrownswiss: vacasengordebrownswiss,
      vacasengordecriollo: vacasengordecriollo,
      vacasengordeholstein: vacasengordeholstein,
      vacasengordeotraraza: vacasengordeotraraza,

      toretesbrownswiss: toretesbrownswiss,
      toretescriollo: toretescriollo,
      toretesholstein: toretesholstein,
      toretesotraraza: toretesotraraza,

      litrosepocasecadia: litrosepocasecadia,
      litroepocalluviadia: litroepocalluviadia,
      vacasproduccionepocaseca: vacasproduccionepocaseca,
      vacasproduccionepocalluvia: vacasproduccionepocalluvia,
      ventalechepublicomercado: ventalechepublicomercado,
      ventalechepublicomercadocosto: ventalechepublicomercadocosto,
      ventalecheplantaquesera: ventalecheplantaquesera,
      ventalecheplantaqueseracosto: ventalecheplantaqueseracosto,
      ventalechegloria: ventalechegloria,
      ventalechegloriacosto: ventalechegloriacosto,
      ventalecheautonconsumo: ventalecheautonconsumo,
      ventalecheautoinsumo: ventalecheautoinsumo,
      ventatoros: ventatoros,
      ventatoroscosto: ventatoroscosto,

      // condiciones de ordeño
      ordeniorealiza: ordeniorealiza,
      lecheenvasado: lecheenvasado,
      almacenamientoleche: almacenamientoleche,
      // tipo de reproduccion
      tiporeproduccion: tiporeproduccion,
      tipoinseminador: tipoinseminador,
      quieninsemina: quieninsemina,

      //* categoria ovino
      totovinocorriedale: totovinocorriedale,
      totovinohampshire: totovinohampshire,
      totovinocriollo: totovinocriollo,
      totovinoBlackbelly: totovinoBlackbelly,
      totovinootras: totovinootras,

      machocorriedale: machocorriedale,
      machohampshire: machohampshire,
      machocriollo: machocriollo,
      machoBlackbelly: machoBlackbelly,
      machootras: machootras,

      hembracorriedale: hembracorriedale,
      hembrahampshire: hembrahampshire,
      hembracriollo: hembracriollo,
      hembraBlackbelly: hembraBlackbelly,
      hembraotras: hembraotras,

      ventaovinomercadopublico: ventaovinomercadopublico,
      ventaovinomercadopublicocosto: ventaovinomercadopublicocosto,
      ventacarnicero: ventacarnicero,
      ventacarnicerocosto: ventacarnicerocosto,
      ventaovinootros: ventaovinootros,
      ventaovinootroscosto: ventaovinootroscosto,
      ventaovinoauto: ventaovinoauto,

      //animales menores
      menorespolloengorde: menorespolloengorde,
      menoresgallina: menoresgallina,
      menoresporcinos: menoresporcinos,
      menoresllamas: menoresllamas,
      menoresalpaca: menoresalpaca,
      menorescaprinos: menorescaprinos,
      menorescuyes: menorescuyes,
      // menorescanes: menorescanes,

      //
      perteneceasociacioncooperativa: perteneceasociacioncooperativa,
      cualasociacioncooperativa: cualasociacioncooperativa,
      institucioncapacita: institucioncapacita,
      //
      // latutud: latutud,
      // longitud: longitud,

      //image
      // image: image,
      // nombredelfocalizador: nombredelfocalizador,
      // timestamp: Date,
    };

    await updateDoc(productor, data);
    navigate("/");
  };

  const getProductorById = async (id) => {
    const productor = await getDoc(doc(db, "basedsaman", id));
    if (productor.exists()) {
      //* 1: I. DATOS GENERALES DEL PRODUCTOR
      setNombre(productor.data().nombre);
      setApellido(productor.data().apellido);
      setDni(productor.data().dni);
      setEdad(productor.data().edad);
      setCentropoblado(productor.data().centropoblado);
      // setComunidadsector(productor.data().comunidadsector);
      const comunidadsectorValue = productor.data().comunidadsector;
      const comunidadsectorArray =
        typeof comunidadsectorValue === "string"
          ? [comunidadsectorValue]
          : comunidadsectorValue;
      setComunidadsector(comunidadsectorArray);
      setNiveducacion(productor.data().niveducacion);
      setGenero(productor.data().genero);
      setNcelular(productor.data().ncelular);
      setChijos(productor.data().chijos);
      setChestudian(productor.data().chestudian);
      setObservaciones(productor.data().observaciones);

      // 1.2. Datos economicas
      setActividadagropecuaria(productor.data().actividadagropecuaria);
      setActividadconstruccion(productor.data().actividadconstruccion);
      setActividadtransportescomunidaciones(
        productor.data().actividadtransportescomunidaciones
      );
      setActividadmineria(productor.data().actividadmineria);
      setActividadcomercio(productor.data().actividadcomercio);
      setActividadotros(productor.data().actividadotros);
      // INGRESO TOTAL
      setIngresototal(productor.data().ingresototal);
      // SERVICIOS BASICOS
      setServicioagua(productor.data().servicioagua);
      setEnergiaelectrica(productor.data().energiaelectrica);
      setEnergiatrifasica(productor.data().energiatrifasica);
      setInternet(productor.data().internet);

      setAccesovial(productor.data().accesovial);
      // TIPO DE MATERIAL DE CONSTRUCION DE LA VIVIENDA
      setTipodematerialdeconstruccion(
        productor.data().tipodematerialdeconstruccion
      );
      //* 2: II. PISO FORRAJERO
      setTotaldehectareas(productor.data().totaldehectareas);
      setAreapasiva(productor.data().areapasiva);
      setAreaactiva(productor.data().areaactiva);
      setCantidaddealfalfa(productor.data().cantidaddealfalfa);
      setCantidadderyegrass(productor.data().cantidadderyegrass);
      setCantidaddeavenaforrajera(productor.data().cantidaddeavenaforrajera);
      setCantidaddepastonatural(productor.data().cantidaddepastonatural);
      setCantidaddalfalfa(productor.data().cantidaddalfalfa);
      setCultivopapa(productor.data().cultivopapa);
      setCultivoquinua(productor.data().cultivoquinua);
      setCultivocaniua(productor.data().cultivocaniua);
      setCultivohabas(productor.data().cultivohabas);
      setCultivootros(productor.data().cultivootros);

      //* 3: III. ACESO AL RIEGO
      // 3.1. ¿tiene riego?
      setTieneriego(productor.data().tieneriego);
      // 3.2. ¿Qué fuente de agua dispone Ud.?
      setFuentedeagua(productor.data().fuentedeagua);
      // 3.4. ¿Considera usted que el agua de riego esta contaminada?
      setAguaderiegocontaminada(productor.data().aguaderiegocontaminada);
      setRelaveminero(productor.data().relaveminero);
      setDesagueindustrial(productor.data().desagueindustrial);
      setOtrassustancias(productor.data().otrassustancias);

      // * 4: IV. INFRAESTRUCTURA AGROPECUARIA QUE CUENTA EN PRODUCTOR
      setCobertizos(productor.data().cobertizos);
      setMiniestablos(productor.data().miniestablos);
      setComedero(productor.data().comedero);
      setBebederos(productor.data().bebederos);
      setTerneraje(productor.data().terneraje);
      setSalaordenio(productor.data().salaordenio);
      setPlataformadeordenio(productor.data().plataformadeordenio);
      setInvernadero(productor.data().invernadero);
      setGalponcuyes(productor.data().galponcuyes);
      setSilosforraje(productor.data().silosforraje);
      setCercoelectrico(productor.data().cercoelectrico);
      setCercotapial(productor.data().cercotapial);
      setCercopiedra(productor.data().cercopiedra);
      setEstablolechero(productor.data().establolechero);

      //* 5: V. MAQUINARIAS, EQUIPOS Y HERRAMIENTAS QUE DISPONE LA UNIDAD PRODUCTIVA O NUCLEO FAMILIAR
      setTractoragricola(productor.data().tractoragricola);
      setImpleagricola(productor.data().impleagricola);
      setOrdeniadora(productor.data().ordeniadora);
      setMolineraforraje(productor.data().molineraforraje);
      setMotoguadania(productor.data().motoguadania);
      setMotocarga(productor.data().motocarga);
      setPorongo(productor.data().porongo);
      setPicadoraforraje(productor.data().picadoraforraje);
      setOtrosmaquinarias(productor.data().otrosmaquinarias);

      // * 6: VI. ACCESO A FINANCIAMIENTO DEL PRODUCTOR
      setTienecreditofinanciamiento(
        productor.data().tienecreditofinanciamiento
      );
      setTienecreditocajamunicial(productor.data().tienecreditocajamunicial);
      setTienecreditocajarural(productor.data().tienecreditocajarural);
      setTienecreditocooperativaahorro(
        productor.data().tienecreditocooperativaahorro
      );
      setTienecreditobanco(productor.data().tienecreditobanco);
      setTienecreditoagrobanco(productor.data().tienecreditoagrobanco);
      setTienecreditoong(productor.data().tienecreditoong);
      setTienecreditootrosfondos(productor.data().tienecreditootrosfondos);
      // 6.2. ¿PARA QUE SOLICITO EL CREDITO?
      setSolicitocreditoadquisemilla(
        productor.data().solicitocreditoadquisemilla
      );
      setSolicitocreditoadquimaquinarias(
        productor.data().solicitocreditoadquimaquinarias
      );
      setSolicitocreditoadquiganadoovino(
        productor.data().solicitocreditoadquiganadoovino
      );
      setSolicitocreditoadquiganadovacuno(
        productor.data().solicitocreditoadquiganadovacuno
      );
      setSolicitocreditopagarotrocredito(
        productor.data().solicitocreditopagarotrocredito
      );
      setSolicitocreditopagarotros(productor.data().solicitocreditopagarotros);

      //* 7: VII. RECIBE FINANCIMIENTO DE PROYECTOS Y/O PLAN DE NEGOCIO POR OTRAS ENTIDADES PUBLICAS O PRIVADAS.
      setAccesofinanciamiento(productor.data().accesofinanciamiento);
      setAccesofinandistrital(productor.data().accesofinandistrital);
      setAccesofinanproregional(productor.data().accesofinanproregional);
      setAccedofinanpromunicipal(productor.data().accedofinanpromunicipal);
      setAccedofinanfondoempleo(productor.data().accedofinanfondoempleo);
      setAccedofinancoopinternacional(
        productor.data().accedofinancoopinternacional
      );
      setAccedofinanagroideas(productor.data().accedofinanagroideas);
      setAccedofinanagrorural(productor.data().accedofinanagrorural);
      setAccedofinanfoncodes(productor.data().accedofinanfoncodes);
      setAccedofinanotros(productor.data().accedofinanotros);

      // * 8: VIII. CARACTERISTICAS DEL GANADO VACUNO
      // 8.1. Nº total de vacunos
      setNtotalvacunobrownswiss(productor.data().ntotalvacunobrownswiss);
      setNtotalvacunocriollo(productor.data().ntotalvacunocriollo);
      setNtotalvacunoholstein(productor.data().ntotalvacunoholstein);
      setNtotalvacunootraraza(productor.data().ntotalvacunootraraza);
      // 8.2. Total vacas
      setTotvacasbrownswiss(productor.data().totvacasbrownswiss);
      setTotvacascriollo(productor.data().totvacascriollo);
      setTotvacasholstein(productor.data().totvacasholstein);
      setTotvacasotraraza(productor.data().totvacasotraraza);
      // 8.3. Total Toros
      setTottorosbrownswiss(productor.data().tottorosbrownswiss);
      setTottoroscriollo(productor.data().tottoroscriollo);
      setTottorosholstein(productor.data().tottorosholstein);
      setTottorosotraraza(productor.data().tottorosotraraza);
      // 8.4. Vacas en produccion
      setVacasprodbrownswiss(productor.data().vacasprodbrownswiss);
      setVacasprodcriollo(productor.data().vacasprodcriollo);
      setVacasprodholstein(productor.data().vacasprodholstein);
      setVacasprodotraraza(productor.data().vacasprodotraraza);
      // 8.5. Terneras(menores a 1año )
      setTernerasbrownswiss(productor.data().ternerasbrownswiss);
      setTernerascriollo(productor.data().ternerascriollo);
      setTernerasholstein(productor.data().ternerasholstein);
      setTernerasotraraza(productor.data().ternerasotraraza);
      // 8.6. Terneros(menores a 1año )
      setTernerobrownswiss(productor.data().ternerobrownswiss);
      setTernerocriollo(productor.data().ternerocriollo);
      setTerneroholstein(productor.data().terneroholstein);
      setTernerootraraza(productor.data().ternerootraraza);
      // 8.7. Vaquillas (entre 1 a 2 años )
      setVaquillasbrownswiss(productor.data().vaquillasbrownswiss);
      setVaquillascriollo(productor.data().vaquillascriollo);
      setVaquillasholstein(productor.data().vaquillasholstein);
      setVaquillasotraraza(productor.data().vaquillasotraraza);
      // 8.8.Vaquillonas (primer parto)
      setVaquillonabrownswiss(productor.data().vaquillonabrownswiss);
      setVaquillonacriollo(productor.data().vaquillonacriollo);
      setVaquillonaholstein(productor.data().vaquillonaholstein);
      setVaquillonaotraraza(productor.data().vaquillonaotraraza);
      // 8.9. Vacas de engorde
      setVacasengordebrownswiss(productor.data().vacasengordebrownswiss);
      setVacasengordecriollo(productor.data().vacasengordecriollo);
      setVacasengordeholstein(productor.data().vacasengordeholstein);
      setVacasengordeotraraza(productor.data().vacasengordeotraraza);
      // 8.10. Venta de toros engorde
      setToretesbrownswiss(productor.data().toretesbrownswiss);
      setToretescriollo(productor.data().toretescriollo);
      setToretesholstein(productor.data().toretesholstein);
      setToretesotraraza(productor.data().toretesotraraza);
      // 8.11.¿Cuántos litros por dia produce la leche?
      setLitrosepocasecadia(productor.data().litrosepocasecadia);
      setLitroepocalluviadia(productor.data().litroepocalluviadia);
      // 8.12.¿Cuántas vacas tiene en produción?
      setVacasproduccionepocaseca(productor.data().vacasproduccionepocaseca);
      setVacasproduccionepocalluvia(
        productor.data().vacasproduccionepocalluvia
      );
      // 8.13. ¿Cuál es el destino de su producion de leche?
      setVentalechepublicomercado(productor.data().ventalechepublicomercado);
      setVentalechepublicomercadocosto(
        productor.data().ventalechepublicomercadocosto
      );
      setVentalecheplantaquesera(productor.data().ventalecheplantaquesera);
      setVentalecheplantaqueseracosto(
        productor.data().ventalecheplantaqueseracosto
      );
      setVentalechegloria(productor.data().ventalechegloria);
      setVentalechegloriacosto(productor.data().ventalechegloriacosto);
      setVentalecheautonconsumo(productor.data().ventalecheautonconsumo);
      setVentalecheautoinsumo(productor.data().ventalecheautoinsumo);
      setVentatoros(productor.data().ventatoros);
      setVentatoroscosto(productor.data().ventatoroscosto);

      //* 9: XI. CONDICIONES DE ORDEÑO
      setOrdeniorealiza(productor.data().ordeniorealiza);
      setLecheenvasado(productor.data().lecheenvasado);
      setAlmacenamientoleche(productor.data().almacenamientoleche);

      //* 10: XI.TIPO DE REPRODUCION
      setTiporeproduccion(productor.data().tiporeproduccion);
      setTipoinseminador(productor.data().tipoinseminador);
      setQuieninsemina(productor.data().quieninsemina);

      //* 11: XI. CATEGORIAS DE GANADO OVINO
      // 11.1.Nº total de ovinos
      setTotovinocorriedale(productor.data().totovinocorriedale);
      setTotovinohampshire(productor.data().totovinohampshire);
      setTotovinocriollo(productor.data().totovinocriollo);
      setTotovinoBlackbelly(productor.data().totovinoBlackbelly);
      setTotovinootras(productor.data().totovinootras);
      // 11.2. Nro. de Machos
      setMachocorriedale(productor.data().machocorriedale);
      setMachohampshire(productor.data().machohampshire);
      setMachocriollo(productor.data().machocriollo);
      setMachoBlackbelly(productor.data().machoBlackbelly);
      setMachootras(productor.data().machootras);
      // 11.3. Nro. de Hembras
      setHembracorriedale(productor.data().hembracorriedale);
      setHembrahampshire(productor.data().hembrahampshire);
      setHembracriollo(productor.data().hembracriollo);
      setHembraBlackbelly(productor.data().hembraBlackbelly);
      setHembraotras(productor.data().hembraotras);
      // 11.4.¿Cuál es el destino comercial de sus ovinos?
      setVentaovinomercadopublico(productor.data().ventaovinomercadopublico);
      setVentaovinomercadopublicocosto(
        productor.data().ventaovinomercadopublicocosto
      );
      setVentacarnicero(productor.data().ventacarnicero);
      setVentacarnicerocosto(productor.data().ventacarnicerocosto);
      setVentaovinootros(productor.data().ventaovinootros);
      setVentaovinootroscosto(productor.data().ventaovinootroscosto);
      setVentaovinoauto(productor.data().ventaovinoauto);

      //* 12: XII. ¿QUE ANIMALES MENORES CRIA UD.?
      setMenorespolloengorde(productor.data().menorespolloengorde);
      setMenoresgallina(productor.data().menoresgallina);
      setMenoresporcinos(productor.data().menoresporcinos);
      setMenoresllamas(productor.data().menoresllamas);
      setMenoresalpaca(productor.data().menoresalpaca);
      setMenorescaprinos(productor.data().menorescaprinos);
      setMenorescuyes(productor.data().menorescuyes);
      //  setMenorescanes(productor.data().menorescanes);

      // * 13: XII.CAPACITACION
      setPerteneceasociacioncooperativa(
        productor.data().perteneceasociacioncooperativa
      );
      setCualasociacioncooperativa(productor.data().cualasociacioncooperativa);
      setInstitucioncapacita(productor.data().institucioncapacita);
      //
      // setLatutud(productor.data().latutud);
      // setLongitud(productor.data().longitud);
      //
      // setImage(productor.data().image);
      // setNombredelfocalizador(productor.data().nombredelfocalizador);
    } else {
      console.log("El Productor no existe");
    }
  };

  useEffect(() => {
    getProductorById(id);
  }, [id]);

  // useEffect(() => {
  //   // Cargar la información de Firestore y establecer el estado de "centropoblado"
  //   const loadCentropobladoFromFirestore = async () => {
  //     // Aquí obtén los datos del centro poblado desde Firestore según el ID, por ejemplo:
  //     const productorId = "el_id_del_productor_a_editar";
  //     const productorRef = doc(db, "", productorId);
  //     const productor = await getDoc(productorRef);
  //     if (productor.exists()) {
  //       setCentropoblado(productor.data().centropoblado);
  //     }
  //   };

  //   loadCentropobladoFromFirestore();
  // }, []);

  const handleCentroPobladoChange = (e) => {
    const selectedCentroPoblado = e.target.value;
    setCentropoblado(selectedCentroPoblado);

    switch (selectedCentroPoblado) {
      case "CP CHUCARIPO":
        setComunidadsector([
          "RINCONADA CARIGUITA",
          "PAMPA CARIGUITA",
          "PAMPA CHUCARIPO",
          "LITERO",
          "JASANA CHICO",
          "HOMBRE HUATY",
          "HIRUITO",
          "CCORPA",
          "CANTAGACHI",
          "ALTO FURUNCHA",
        ]);
        break;
      case "ZONA CENTRO":
        setComunidadsector([
          "RINCONADA SAMAN",
          "MUNI CHICO",
          "MACHACA ISLA",
          "ISLA SAMAN",
          "DESVIO SAMAN",
          "COLLINCHA QUINCHARAPI",
          "CHILCHE",
          "CCORPA SAMAN",
          "KANCOLLA MACHA",
        ]);
        break;
      case "CP QUEJON MOCCO":
        setComunidadsector([
          "CHEJACHI",
          "SANTA CLARA",
          "TAMBO",
          "1° HUCUATA",
          "QUEJON VALLE",
          "2° HUCUATA",
          "KAPALLIA",
          "LLATHA MOCCO",
        ]);
        break;
      case "CP MUNI GRANDE":
        setComunidadsector([
          "TITIHUARIJA",
          "MUNI PAMPA",
          "MUNI SALINAS",
          "JERGACHI",
          "YCALLO",
        ]);
        break;
      case "CP JASANA GRANDE":
        setComunidadsector([
          "CARPI PAMPA",
          "ESQUELE",
          "ISLA",
          "PATALLA",
          "ISILLOA",
        ]);
        break;
        case "CP SAN JUAN DE CHACAMARCA":
        setComunidadsector(["PRIMER CHACAMARCA", "SEGUNDO CHACAMARCA"]);
        break;
      default:
        setComunidadsector([]);
    }

    if (typeof comunidadsector === "string") {
      setComunidadsector([comunidadsector]);
    }
  };

  return (
    <div className="mx-10 my-10">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <h1 className="font-bold text-2xl items-baseline font-mono">
          Actualizar datos del Productor
        </h1>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Datos personales
            </h6>
            <Link
              to="/"
              className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Regresar
            </Link>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={update}>
            {/* //? I. DATOS GENERALES DEL PRODUCTOR  */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    nombre
                  </label>
                  <input
                    value={nombre}
                    onChange={(e) => {
                      setNombre(e.target.value);
                    }}
                    placeholder="Introduce Nombre"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Apellido
                  </label>
                  <input
                    value={apellido}
                    onChange={(e) => {
                      setApellido(e.target.value);
                    }}
                    placeholder="Introduce apellido"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    DNI
                  </label>
                  <input
                    value={dni}
                    onChange={(event) => {
                      setDni(event.target.value);
                    }}
                    placeholder="Introduce dni"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Centro Poblado
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={centropoblado}
                    onChange={handleCentroPobladoChange}
                  >
                    <option value="">Seleccione un Centro Poblado...</option>
                    {centroPoblados.map((centroPoblado) => (
                      <option
                        key={centroPoblado.id}
                        value={centroPoblado.nombre}
                      >
                        {centroPoblado.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    zona centro / comunidad sector
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    // value={comunidadsector}
                    value={comunidadsector[0]}
                    onChange={(e) => setComunidadsector([e.target.value])}
                    // onChange={(e) => setComunidadsector([e.target.value])}
                    // onChange={(e) => setComunidadsector(e.target.value)}
                  >
                    <option value="">
                      Seleccione una Comunidad o Sector...
                    </option>
                    {comunidadsector.map((comunidad) => (
                      <option key={comunidad} value={comunidad}>
                        {comunidad}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Nivel de educacion
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={niveducacion}
                    onChange={(e) => setNiveducacion(e.target.value)}
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Sin nivel">Sin nivel</option>
                    <option value="Primaria">Primaria</option>
                    <option value="Secundaria">Secundaria</option>
                    <option value="Superior">Superior</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Genero
                  </label>
                  <select
                    value={genero}
                    onChange={(e) => {
                      setGenero(e.target.value);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option selected>Seleccione una opción</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Edad
                  </label>
                  <input
                    value={edad}
                    onChange={(event) => {
                      setEdad(event.target.value);
                    }}
                    placeholder="Introdusca edad"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Numero Celular
                  </label>
                  <input
                    value={ncelular}
                    onChange={(event) => {
                      setNcelular(event.target.value);
                    }}
                    placeholder="Introdusca numero celular"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Cantidad de hijos
                  </label>
                  <input
                    value={chijos}
                    onChange={(event) => {
                      setChijos(event.target.value);
                    }}
                    placeholder="Introduce dni"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Cuantos estudian
                  </label>
                  <input
                    value={chestudian}
                    onChange={(event) => {
                      setChestudian(event.target.value);
                    }}
                    placeholder="Introduce Dato"
                    type="number"
                    className="border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-red-600 text-xs font-bold mb-2">
                    observaciónes
                  </label>
                  <textarea
                    className="w-full py-2 px-3 placeholder-red-400 text-red-500 border-red-200 rounded-md border-2 mt-0 focus:outline-none focus:border-red-400 text-sm "
                    placeholder="Escriba aquí una descripción detallada..."
                    value={observaciones}
                    onChange={(e) => setObservaciones(e.target.value)}
                  ></textarea>
                </div>
              </div>


            </div>

            {/* //?1.2. Datos economicas */}
            <hr className="mt-6 border-b-1 border-blue-900" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              Datos de Actividad Economica
            </h6>
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded">
              <span className="w-full mb-3 text-blue-500 font-bold">
                ACTIVIDAD ECONOMICA
              </span>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={actividadagropecuaria}
                      onChange={() => {
                        setActividadagropecuaria(!actividadagropecuaria);
                      }}
                      id="checkbox16"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox16"
                      className="uppercase ml-6 text-xs text-blueGray-600 font-bold"
                    >
                      Agropecuaria
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={actividadconstruccion}
                      onChange={() => {
                        setActividadconstruccion(!actividadconstruccion);
                      }}
                      id="checkbox17"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox17"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 "
                    >
                      Construccion
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={actividadtransportescomunidaciones}
                      onChange={() => {
                        setActividadtransportescomunidaciones(
                          !actividadtransportescomunidaciones
                        );
                      }}
                      id="checkbox18"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox18"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600"
                    >
                      Transporte y comunicaciones
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={actividadmineria}
                      onChange={() => {
                        setActividadmineria(!actividadmineria);
                      }}
                      id="checkbox19"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox19"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 "
                    >
                      Mineria
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={actividadcomercio}
                      onChange={() => {
                        setActividadcomercio(!actividadcomercio);
                      }}
                      id="checkbox20"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox20"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 "
                    >
                      Comercio
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={actividadotros}
                      onChange={() => {
                        setActividadotros(!actividadotros);
                      }}
                      id="checkbox21"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox21"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 "
                    >
                      Otros
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                INGRESO TOTAL
              </span>
              <div className="w-full pb-2">
                <select
                  value={ingresototal}
                  onChange={(e) => {
                    setIngresototal(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
                >
                  <option selected>Seleccione una opción</option>
                  <option value="0 a 500">0 a 500</option>
                  <option value="501 a 1000">501 a 1000</option>
                  <option value="Mayor a 1000">Mayor a 1000</option>
                </select>
              </div>
            </div>

            {/* //! SERVICIOS BASICOS */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded">
              <span className="w-full mb-3 text-blue-500 font-bold">
                SERVICIOS BASICOS
              </span>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={servicioagua}
                      onChange={() => {
                        setServicioagua(!servicioagua);
                      }}
                      id="checkbox22"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox22"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 "
                    >
                      Agua
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={energiaelectrica}
                      onChange={() => {
                        setEnergiaelectrica(!energiaelectrica);
                      }}
                      id="checkbox23"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox23"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600"
                    >
                      Energía Monofasica
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={energiatrifasica}
                      onChange={() => {
                        setEnergiatrifasica(!energiatrifasica);
                      }}
                      id="checkbox24"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox24"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600"
                    >
                      Energia Trifasica
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={internet}
                      onChange={() => {
                        setInternet(!internet);
                      }}
                      id="checkbox25"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox25"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600"
                    >
                      Acceso a Internet
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* acceso vial */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold uppercase">
                Acceso vial
              </span>
              <div className="w-full pb-2">
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
                  value={accesovial}
                  onChange={(e) => setAccesovial(e.target.value)}
                >
                  <option selected>Seleccione una opción</option>
                  <option value="Bueno">Bueno</option>
                  <option value="Malo">Malo</option>
                </select>
              </div>
            </div>

            {/* tipo de material de construcion  */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                TIPO DE MATERIAL DE CONSTRUCION DE LA VIVIENDA
              </span>
              <div className="w-full pb-2">
                <select
                  value={tipodematerialdeconstruccion}
                  onChange={(e) => {
                    setTipodematerialdeconstruccion(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
                >
                  <option selected>Seleccione una opción</option>
                  <option value="Material noble">Material noble</option>
                  <option value="Material rustico">Material rustico</option>
                </select>
              </div>
            </div>

            {/* //?   II. PISO FORRAJERO */}
            <hr className="mt-6 border-b-1 border-blueGray-900" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              Piso Forrajero
            </h6>
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.1. total de hectareas
                  </label>
                  <input
                    value={totaldehectareas}
                    onChange={(event) => {
                      setTotaldehectareas(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.2. area pasiva
                  </label>
                  <input
                    value={areapasiva}
                    onChange={(event) => {
                      setAreapasiva(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.3. area activa{" "}
                  </label>
                  <input
                    value={areaactiva}
                    onChange={(event) => {
                      setAreaactiva(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.4. Has de alfalfa
                  </label>
                  <input
                    value={cantidaddealfalfa}
                    onChange={(event) => {
                      setCantidaddealfalfa(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.5. Has de rye grass
                  </label>
                  <input
                    value={cantidadderyegrass}
                    onChange={(event) => {
                      setCantidadderyegrass(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.6.Has de avena forrajera
                  </label>
                  <input
                    value={cantidaddeavenaforrajera}
                    onChange={(event) => {
                      setCantidaddeavenaforrajera(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.7. Has de cebada forrajera
                  </label>
                  <input
                    value={cantidaddecebadaforrajera}
                    onChange={(event) => {
                      setCantidaddecebadaforrajera(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.8. has de pastos naturales
                  </label>
                  <input
                    value={cantidaddepastonatural}
                    onChange={(event) => {
                      setCantidaddepastonatural(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    2.9. Area disponible para alfalfa
                  </label>
                  <input
                    value={cantidaddalfalfa}
                    onChange={(event) => {
                      setCantidaddalfalfa(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Papa
                  </label>
                  <input
                    value={cultivopapa}
                    onChange={(event) => {
                      setCultivopapa(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Quinua
                  </label>
                  <input
                    value={cultivoquinua}
                    onChange={(event) => {
                      setCultivoquinua(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Cañihua
                  </label>
                  <input
                    value={cultivocaniua}
                    onChange={(event) => {
                      setCultivocaniua(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Habas
                  </label>
                  <input
                    value={cultivohabas}
                    onChange={(event) => {
                      setCultivohabas(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Otros
                  </label>
                  <input
                    value={cultivootros}
                    onChange={(event) => {
                      setCultivootros(event.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //? III. ACESO AL RIEGO */}
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              III. ACESO AL RIEGO
            </h6>

            {/* //!  3.1. ¿tiene riego? */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                3.1. ¿Tiene riego?
              </span>
              <div className="w-full pb-2">
                <select
                  value={tieneriego}
                  onChange={(e) => {
                    setTieneriego(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
                >
                  <option selected>Seleccione una opción</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>

            {/* //! 3.2. ¿Qué fuente de agua dispone Ud.? */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                3.2. ¿Que fuente de agua dispone Ud.?
              </span>
              <div className="w-full pb-2">
                <select
                  value={fuentedeagua}
                  onChange={(e) => {
                    setFuentedeagua(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
                >
                  <option selected>Seleccione una opción</option>
                  <option value="Pozo artesanal">Pozo artesanal</option>
                  <option value="Pozo tubular">Pozo tubular</option>
                  <option value="Rio">Rio</option>
                  <option value="Lago o laguna">Lago o laguna</option>
                  <option value="Manantial">Manantial</option>
                  <option value="Otros">Otros</option>
                </select>
              </div>
            </div>

            {/* //!  3.4. ¿Considera usted que el agua de riego esta contaminada? */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                3.4. ¿Considera usted que el agua de riego esta contaminada?
              </span>
              <div className="w-full pb-2">
                <select
                  value={aguaderiegocontaminada}
                  onChange={(e) => {
                    setAguaderiegocontaminada(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
                >
                  <option selected>Seleccione una opción</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={relaveminero}
                      onChange={() => {
                        setRelaveminero(!relaveminero);
                      }}
                      id="checkbox17"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox17"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Contaminada por relaves mineros
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={desagueindustrial}
                      onChange={() => {
                        setDesagueindustrial(!desagueindustrial);
                      }}
                      id="checkbox17"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox17"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Contaminada por plantas queseras
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={otrassustancias}
                      onChange={() => {
                        setOtrassustancias(!otrassustancias);
                      }}
                      id="checkbox17"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox17"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Contaminada por otras sustancias
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* //? IV. INFRAESTRUCTURA AGROPECUARIA QUE CUENTA EN PRODUCTOR */}
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              IV. INFRAESTRUCTURA AGROPECUARIA QUE CUENTA EN PRODUCTOR
            </h6>
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={cobertizos}
                    onChange={() => {
                      setCobertizos(!cobertizos);
                    }}
                    id="checkbox30"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox30"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.1. Cobertizos
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={miniestablos}
                    onChange={() => {
                      setMiniestablos(!miniestablos);
                    }}
                    id="checkbox31"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox31"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.2. Miniestablos
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={comedero}
                    onChange={() => {
                      setComedero(!comedero);
                    }}
                    id="checkbox32"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox32"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.3. Comederos
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={bebederos}
                    onChange={() => {
                      setBebederos(!bebederos);
                    }}
                    id="checkbox33"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox33"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 "
                  >
                    4.4. Bebederos
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={terneraje}
                    onChange={() => {
                      setTerneraje(!terneraje);
                    }}
                    id="checkbox34"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox34"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 "
                  >
                    4.5. Ternejare
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={salaordenio}
                    onChange={() => {
                      setSalaordenio(!salaordenio);
                    }}
                    id="checkbox34"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox34"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.6. Sala de ordeño
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={plataformadeordenio}
                    onChange={() => {
                      setPlataformadeordenio(!plataformadeordenio);
                    }}
                    id="checkbox35"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox35"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.7. Plataforma de ordeño
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={invernadero}
                    onChange={() => {
                      setInvernadero(!invernadero);
                    }}
                    id="checkbox36"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox36"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.8. invernadero
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={galponcuyes}
                    onChange={() => {
                      setGalponcuyes(!galponcuyes);
                    }}
                    id="checkbox37"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox37"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.9. Galpon de cuyes
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={silosforraje}
                    onChange={() => {
                      setSilosforraje(!silosforraje);
                    }}
                    id="checkbox38"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox38"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.10. Heniles
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={cercoelectrico}
                    onChange={() => {
                      setTieneriego(!cercoelectrico);
                    }}
                    id="checkbox39"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox39"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.11. Cerco electrico
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={cercotapial}
                    onChange={() => {
                      setCercotapial(!cercotapial);
                    }}
                    id="checkbox40"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox40"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.13. Cerco de adobe
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={cercopiedra}
                    onChange={() => {
                      setCercopiedra(!cercopiedra);
                    }}
                    id="checkbox41"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox41"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.14. Cerco de piedra
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={establolechero}
                    onChange={() => {
                      setEstablolechero(!establolechero);
                    }}
                    id="checkbox42"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox42"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    4.15. Establo
                  </label>
                </div>
              </div>
            </div>

            {/* //? V. MAQUINARIAS, EQUIPOS Y HERRAMIENTAS QUE DISPONE LA UNIDAD PRODUCTIVA O NUCLEO FAMILIAR */}
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              V. MAQUINARIAS, EQUIPOS Y HERRAMIENTAS QUE DISPONE LA UNIDAD
              PRODUCTIVA O NUCLEO FAMILIAR
            </h6>
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tractoragricola}
                    onChange={() => {
                      setTractoragricola(!tractoragricola);
                    }}
                    id="checkbox45"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox45"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.1. Tractor agricola
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={impleagricola}
                    onChange={() => {
                      setImpleagricola(!impleagricola);
                    }}
                    id="checkbox46"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox46"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.2. Implementos agricolas
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={ordeniadora}
                    onChange={() => {
                      setOrdeniadora(!ordeniadora);
                    }}
                    id="checkbox47"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox47"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.3. Ordeñadora
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={molineraforraje}
                    onChange={() => {
                      setMolineraforraje(!molineraforraje);
                    }}
                    id="checkbox48"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox48"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.4. Molinera de forraje
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={motoguadania}
                    onChange={() => {
                      setMotoguadania(!motoguadania);
                    }}
                    id="checkbox49"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox49"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.5. Motoguadaña
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={motocarga}
                    onChange={() => {
                      setMotocarga(!motocarga);
                    }}
                    id="checkbox50"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox50"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.6. Motocarga
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={porongo}
                    onChange={() => {
                      setPorongo(!porongo);
                    }}
                    id="checkbox51"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox51"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.7. Porongos
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={picadoraforraje}
                    onChange={() => {
                      setPicadoraforraje(!picadoraforraje);
                    }}
                    id="checkbox52"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox52"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.8.Picadora de forraje
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={otrosmaquinarias}
                    onChange={() => {
                      setOtrosmaquinarias(!otrosmaquinarias);
                    }}
                    id="checkbox53"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox53"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    5.9.Otro
                  </label>
                </div>
              </div>
            </div>

            {/* //?  VI. ACCESO A FINANCIAMIENTO DEL PRODUCTOR */}
            <hr className="mt-6 border-b-1 border-gray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              VI. ACCESO A FINANCIAMIENTO DEL PRODUCTOR
            </h6>
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    6.1. ¿Tiene usted acceso a creditos para el financiamiento
                    de su unidad productiva? usted o su organizacion a tenido
                    acceso a financiamiento por parte del estado en los ultimos
                    4 años
                  </label>
                  <select
                    value={tienecreditofinanciamiento}
                    onChange={(e) => {
                      setTienecreditofinanciamiento(e.target.value);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option selected>Seleccione una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tienecreditocajamunicial}
                    onChange={() => {
                      setTienecreditocajamunicial(!tienecreditocajamunicial);
                    }}
                    id="checkbox55"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox55"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Cajas municipales
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tienecreditocajarural}
                    onChange={() => {
                      setTienecreditocajarural(!tienecreditocajarural);
                    }}
                    id="checkbox56"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox56"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Caja rural
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tienecreditocooperativaahorro}
                    onChange={() => {
                      setTienecreditocooperativaahorro(
                        !tienecreditocooperativaahorro
                      );
                    }}
                    id="checkbox57"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox57"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Cooperativa de Ahorro y Credito
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tienecreditobanco}
                    onChange={() => {
                      setTienecreditobanco(!tienecreditobanco);
                    }}
                    id="checkbox58"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox58"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Banco
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tienecreditoagrobanco}
                    onChange={() => {
                      setTienecreditoagrobanco(!tienecreditoagrobanco);
                    }}
                    id="checkbox59"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox59"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Agrobanco
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tienecreditoong}
                    onChange={() => {
                      setTienecreditoong(!tienecreditoong);
                    }}
                    id="checkbox60"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox60"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    ONG
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={tienecreditootrosfondos}
                    onChange={() => {
                      setTienecreditootrosfondos(!tienecreditootrosfondos);
                    }}
                    id="checkbox61"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox61"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Otros fondos
                  </label>
                </div>
              </div>
            </div>

            {/* //! 6.2. ¿Para que solicito el credito? */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded">
              <span className="w-full mb-3 text-blue-500 font-bold">
                6.2. ¿Para que solicito el credito?
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={solicitocreditoadquisemilla}
                    onChange={() => {
                      setSolicitocreditoadquisemilla(
                        !solicitocreditoadquisemilla
                      );
                    }}
                    id="checkbox65"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox65"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Adquisicion de semilla
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={solicitocreditoadquimaquinarias}
                    onChange={() => {
                      setSolicitocreditoadquimaquinarias(
                        !solicitocreditoadquimaquinarias
                      );
                    }}
                    id="checkbox66"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox66"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Adquisicion de maquinarias
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={solicitocreditoadquiganadoovino}
                    onChange={() => {
                      setSolicitocreditoadquiganadoovino(
                        !solicitocreditoadquiganadoovino
                      );
                    }}
                    id="checkbox67"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox67"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Capital ganado ovino
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={solicitocreditoadquiganadovacuno}
                    onChange={() => {
                      setSolicitocreditoadquiganadovacuno(
                        !solicitocreditoadquiganadovacuno
                      );
                    }}
                    id="checkbox68"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox68"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Adquisicion de ganado vacuno
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={solicitocreditopagarotrocredito}
                    onChange={() => {
                      setSolicitocreditopagarotrocredito(
                        !solicitocreditopagarotrocredito
                      );
                    }}
                    id="checkbox69"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox69"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    campaña agricola
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={solicitocreditopagarotros}
                    onChange={() => {
                      setSolicitocreditopagarotros(!solicitocreditopagarotros);
                    }}
                    id="checkbox70"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox70"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Otros
                  </label>
                </div>
              </div>
            </div>

            {/* //? VII. RECIBE FINANCIMIENTO DE PROYECTOS Y/O PLAN DE NEGOCIO POR OTRAS ENTIDADES PUBLICAS O PRIVADAS. */}
            <hr className="mt-6 border-b-1 border-gray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              VII. RECIBE FINANCIMIENTO DE PROYECTOS Y/O PLAN DE NEGOCIO POR
              OTRAS ENTIDADES PUBLICAS O PRIVADAS.
            </h6>
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    ¿Usted y su organización han tenido acceso a financiamiento
                    por parte del estado en los ultimos 4 años?
                  </label>
                  <select
                    value={accesofinanciamiento}
                    onChange={(e) => {
                      setAccesofinanciamiento(e.target.value);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option selected>Seleccione una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accesofinandistrital}
                    onChange={() => {
                      setAccesofinandistrital(!accesofinandistrital);
                    }}
                    id="checkbox75"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox75"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Municipio Distrital
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accesofinanproregional}
                    onChange={() => {
                      setAccesofinanproregional(!accesofinanproregional);
                    }}
                    id="checkbox76"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox76"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Procompite Gobierno Regional
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accedofinanpromunicipal}
                    onChange={() => {
                      setAccedofinanpromunicipal(!accedofinanpromunicipal);
                    }}
                    id="checkbox77"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox77"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Procompite Gobieno Municipal
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accedofinanfondoempleo}
                    onChange={() => {
                      setAccedofinanfondoempleo(!accedofinanfondoempleo);
                    }}
                    id="checkbox78"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox78"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Fondoempleo
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accedofinancoopinternacional}
                    onChange={() => {
                      setAccedofinancoopinternacional(
                        !accedofinancoopinternacional
                      );
                    }}
                    id="checkbox79"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox79"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Agroideas
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accedofinanagrorural}
                    onChange={() => {
                      setAccedofinanagrorural(!accedofinanagrorural);
                    }}
                    id="checkbox80"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox80"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Agro Rural
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accedofinanfoncodes}
                    onChange={() => {
                      setAccedofinanfoncodes(!accedofinanfoncodes);
                    }}
                    id="checkbox81"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox81"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Foncodes
                  </label>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    checked={accedofinanotros}
                    onChange={() => {
                      setAccedofinanotros(!accedofinanotros);
                    }}
                    id="checkbox82"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor="checkbox82"
                    className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                  >
                    Otros
                  </label>
                </div>
              </div>
            </div>

            {/* //? VIII. CARACTERISTICAS DEL GANADO VACUNO */}
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              VIII. CARACTERISTICAS DEL GANADO VACUNO
            </h6>
            {/* //! 8.1. Nº total de vacunos */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.1. Nº total de vacunos
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={ntotalvacunobrownswiss}
                    onChange={(e) => {
                      setNtotalvacunobrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={ntotalvacunocriollo}
                    onChange={(e) => {
                      setNtotalvacunocriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={ntotalvacunoholstein}
                    onChange={(e) => {
                      setNtotalvacunoholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={ntotalvacunootraraza}
                    onChange={(e) => {
                      setNtotalvacunootraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.2. Total vacas */}

            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.2. Total vacas
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={totvacasbrownswiss}
                    onChange={(e) => {
                      setTotvacasbrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={totvacascriollo}
                    onChange={(e) => {
                      setTotvacascriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={totvacasholstein}
                    onChange={(e) => {
                      setTotvacasholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={totvacasotraraza}
                    onChange={(e) => {
                      setTotvacasotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.3. Total Toros */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.3. Total Toros
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={tottorosbrownswiss}
                    onChange={(e) => {
                      setTottorosbrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={tottoroscriollo}
                    onChange={(e) => {
                      setTottoroscriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={tottorosholstein}
                    onChange={(e) => {
                      setTottorosholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={tottorosotraraza}
                    onChange={(e) => {
                      setTottorosotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.4. Vacas en produccion */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.4. Vacas en produccion
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={vacasprodbrownswiss}
                    onChange={(e) => {
                      setVacasprodbrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={vacasprodcriollo}
                    onChange={(e) => {
                      setVacasprodcriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={vacasprodholstein}
                    onChange={(e) => {
                      setVacasprodholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={vacasprodotraraza}
                    onChange={(e) => {
                      setVacasprodotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.5. Terneras(menores a 1año ) */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.5. Terneras(menores a 1año )
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={ternerasbrownswiss}
                    onChange={(e) => {
                      setTernerasbrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={ternerascriollo}
                    onChange={(e) => {
                      setTernerascriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={ternerasholstein}
                    onChange={(e) => {
                      setTernerasholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Simmental
                  </label>
                  <input
                    value={ternerasotraraza}
                    onChange={(e) => {
                      setTernerasotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.6. Terneros(menores a 1año ) */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.6. Terneros(menores a 1año )
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={ternerobrownswiss}
                    onChange={(e) => {
                      setTernerobrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={ternerocriollo}
                    onChange={(e) => {
                      setTernerocriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={terneroholstein}
                    onChange={(e) => {
                      setTerneroholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={ternerootraraza}
                    onChange={(e) => {
                      setTernerootraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.7. Vaquillas (entre 1 a 2 años ) */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.7. Vaquillas (entre 1 a 2 años )
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={vaquillasbrownswiss}
                    onChange={(e) => {
                      setVaquillasbrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={vaquillascriollo}
                    onChange={(e) => {
                      setVaquillascriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={vaquillasholstein}
                    onChange={(e) => {
                      setVaquillasholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={vaquillasotraraza}
                    onChange={(e) => {
                      setVaquillasotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.8.Vaquillonas (primer parto) */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.8.Vaquillonas (primer parto)
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={vaquillonabrownswiss}
                    onChange={(e) => {
                      setVaquillonabrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={vaquillonacriollo}
                    onChange={(e) => {
                      setVaquillonacriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={vaquillonaholstein}
                    onChange={(e) => {
                      setVaquillonaholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={vaquillonaotraraza}
                    onChange={(e) => {
                      setVaquillonaotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //!  8.9. Vacas de engorde */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.9. Vacas de engorde
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={vacasengordebrownswiss}
                    onChange={(e) => {
                      setVacasengordebrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={vacasengordecriollo}
                    onChange={(e) => {
                      setVacasengordecriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={vacasengordeholstein}
                    onChange={(e) => {
                      setVacasengordeholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={vacasengordeotraraza}
                    onChange={(e) => {
                      setVacasengordeotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.10. Venta de toros engorde */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.10. Venta de toros engorde
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Brown Swiss
                  </label>
                  <input
                    value={toretesbrownswiss}
                    onChange={(e) => {
                      setToretesbrownswiss(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={toretescriollo}
                    onChange={(e) => {
                      setToretescriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Holstein
                  </label>
                  <input
                    value={toretesholstein}
                    onChange={(e) => {
                      setToretesholstein(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    SIMMENTAL
                  </label>
                  <input
                    value={toretesotraraza}
                    onChange={(e) => {
                      setToretesotraraza(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.11.¿Cuántos litros por dia produce la leche? */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.11.¿Cuántos litros por dia produce la leche?
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Epoca seca
                  </label>
                  <input
                    value={litrosepocasecadia}
                    onChange={(e) => {
                      setLitrosepocasecadia(e.target.value);
                    }}
                    placeholder="L/Dia"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Epoca de lluvias
                  </label>
                  <input
                    value={litroepocalluviadia}
                    onChange={(e) => {
                      setLitroepocalluviadia(e.target.value);
                    }}
                    placeholder="L/Dia"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.12.¿Cuántas vacas tiene en produción? */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.12.¿Cuántas vacas tiene en produción?
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Epoca seca
                  </label>
                  <input
                    value={vacasproduccionepocaseca}
                    onChange={(e) => {
                      setVacasproduccionepocaseca(e.target.value);
                    }}
                    placeholder="L/Dia"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Epoca de lluvias
                  </label>
                  <input
                    value={vacasproduccionepocalluvia}
                    onChange={(e) => {
                      setVacasproduccionepocalluvia(e.target.value);
                    }}
                    placeholder="L/Dia"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! 8.13. ¿Cuál es el destino de su producion de leche?*/}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                8.13. ¿Cuál es el destino de su producion de leche?
              </span>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventalechepublicomercado}
                      onChange={() => {
                        setVentalechepublicomercado(!ventalechepublicomercado);
                      }}
                      id="checkbox11"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox11"
                      className="uppercase ml-2 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Venta de leche al público (mercado)
                    </label>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      value={ventalechepublicomercadocosto}
                      onChange={(e) => {
                        setVentalechepublicomercadocosto(e.target.value);
                      }}
                      placeholder="soles"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventalecheplantaquesera}
                      onChange={() => {
                        setVentalecheplantaquesera(!ventalecheplantaquesera);
                      }}
                      id="checkbox12"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox12"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Venta de leche a plantas queseras
                    </label>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      value={ventalecheplantaqueseracosto}
                      onChange={(e) => {
                        setVentalecheplantaqueseracosto(e.target.value);
                      }}
                      placeholder="soles"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventalechegloria}
                      onChange={() => {
                        setVentalechegloria(!ventalechegloria);
                      }}
                      id="checkbox13"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox13"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Venta a leche gloria
                    </label>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      value={ventalechegloriacosto}
                      onChange={(e) => {
                        setVentalechegloriacosto(e.target.value);
                      }}
                      placeholder="soles"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventalecheautonconsumo}
                      onChange={() => {
                        setVentalecheautonconsumo(!ventalecheautonconsumo);
                      }}
                      id="checkbox14"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox14"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Autoconsumo
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventalecheautoinsumo}
                      onChange={() => {
                        setVentalecheautoinsumo(!ventalecheautoinsumo);
                      }}
                      id="checkbox15"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox15"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Auto insumo
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventatoros}
                      onChange={() => {
                        setVentatoros(!ventatoros);
                      }}
                      id="checkbox13"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox13"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Venta de toros de engorde
                    </label>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      value={ventatoroscosto}
                      onChange={(e) => {
                        setVentatoroscosto(e.target.value);
                      }}
                      placeholder="soles"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* //? XI. CONDICIONES DE ORDEÑO */}
            <hr className="mt-6 border-b-1 border-gray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              XI. CONDICIONES DE ORDEÑO
            </h6>
            {/* //! 9.1.¿ En su hato lechero, el ordeño realiza? */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                9.1.¿ En su hato lechero, el ordeño lo realiza?
              </span>
              <select
                value={ordeniorealiza}
                onChange={(e) => {
                  setOrdeniorealiza(e.target.value);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
              >
                <option selected>Seleccione una opción</option>
                <option value="Manual">Manual</option>
                <option value="ordeniadora">Ordeñadora Mecanica</option>
                <option value="mixto">Dos ordeños</option>
              </select>
            </div>

            {/* //! 9.2.¿En el ordeño, La leche es envasado?  */}
            <div className="flex flex-wrap border mb-2  border-gray-300 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                9.2.¿En el ordeño, La leche es envasado en?
              </span>
              <select
                value={lecheenvasado}
                onChange={(e) => {
                  setLecheenvasado(e.target.value);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2"
              >
                <option selected>Seleccione una opción</option>
                <option value="Balde de plastico">Balde de plastico</option>
                <option value="Balde de aluminio">Balde de aluminio</option>
                <option value="Porongo de aluminio">Porongo de aluminio</option>
                <option value="Balde de acero inox">Balde de acero inox</option>
                <option value="Botella de plastico">Botella de plastico</option>
                <option value="Olla de acero">Olla de acero</option>
              </select>
            </div>

            {/* //! 9.3. ¿Cuáles son las condiciones de almacenamiento de la leche? */}
            <div className="flex flex-wrap border mb-2  border-gray-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                9.3. ¿Cuáles son las condiciones de almacenamiento de la leche?
              </span>
              <select
                value={almacenamientoleche}
                onChange={(e) => {
                  setAlmacenamientoleche(e.target.validationMessage);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Seleccione una opción</option>
                <option value="Bajo sombra">Bajo sombra</option>
                <option value="Refrigeradora">Refrigeradora</option>
                <option value="Tanque Frio">Tanque de frio</option>
                <option value="Con AguaHielo">Con agua y hielo</option>
                <option value="Ollas Enchaqueradas">Ollas enchaqueradas</option>
                <option value="Otro">Otro</option>
              </select>
            </div>

            {/* //? XI.TIPO DE REPRODUCION */}
            <hr className="mt-6 border-b-1 border-gray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              XI.TIPO DE REPRODUCION
            </h6>
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <select
                value={tiporeproduccion}
                onChange={(e) => {
                  setTiporeproduccion(e.target.value);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Seleccione una opción</option>
                <option value="Monta natural">Monta natural</option>
                <option value="Inseminacion artificil">
                  Inseminacion artificil
                </option>
                <option value="transferencia embriones">
                  transferencia de embriones
                </option>
              </select>
            </div>

            {/* //!¿Tipo de inseminador? */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                ¿Tipo de semen?
              </span>
              <select
                value={tipoinseminador}
                onChange={(e) => {
                  setTipoinseminador(e.target.value);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Seleccione una opción</option>
                <option value="Importados">Importados</option>
                <option value="Nacional">Nacional</option>
                <option value="Nacional">Sexado</option>
              </select>
            </div>

            {/* //!¿Quiénes inseminan? */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                ¿Quiénes inseminan?
              </span>
              <select
                value={quieninsemina}
                onChange={(e) => {
                  setQuieninsemina(e.target.value);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Seleccione una opción</option>
                <option value="Privados">Privados</option>
                <option value="Publico">Publico</option>
              </select>
            </div>

            {/* //? IX. CATEGORIAS DE GANADO OVINO */}
            <hr className="mt-6 border-b-1 border-gray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              XI. CATEGORIAS DE GANADO OVINO
            </h6>
            {/* //! Total Ovinos */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                11.1.Nº total de ovinos
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Corriedale
                  </label>
                  <input
                    value={totovinocorriedale}
                    onChange={(e) => {
                      setTotovinocorriedale(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Hampshire brown
                  </label>
                  <input
                    value={totovinohampshire}
                    onChange={(e) => {
                      setTotovinohampshire(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Black belly
                  </label>
                  <input
                    value={totovinoBlackbelly}
                    onChange={(e) => {
                      setTotovinoBlackbelly(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={totovinocriollo}
                    onChange={(e) => {
                      setTotovinocriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Otras
                  </label>
                  <input
                    value={totovinootras}
                    onChange={(e) => {
                      setTotovinootras(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! Macho */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                11.2. Nro. de Machos
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Corriedale
                  </label>
                  <input
                    value={machocorriedale}
                    onChange={(e) => {
                      setMachocorriedale(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Hampshire brown
                  </label>
                  <input
                    value={machohampshire}
                    onChange={(e) => {
                      setMachohampshire(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    id=""
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Black belly
                  </label>
                  <input
                    value={machoBlackbelly}
                    onChange={(e) => {
                      setMachoBlackbelly(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={machocriollo}
                    onChange={(e) => {
                      setMachocriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Otras
                  </label>
                  <input
                    value={machootras}
                    onChange={(e) => {
                      setMachootras(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! Hembra */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                11.3. Nro. de Hembras
              </span>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Corriedale
                  </label>
                  <input
                    value={hembracorriedale}
                    onChange={(e) => {
                      setHembracorriedale(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Hampshire brown
                  </label>
                  <input
                    value={hembrahampshire}
                    onChange={(e) => {
                      setHembrahampshire(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Black belly
                  </label>
                  <input
                    value={hembraBlackbelly}
                    onChange={(e) => {
                      setHembraBlackbelly(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Criollo
                  </label>
                  <input
                    value={hembracriollo}
                    onChange={(e) => {
                      setHembracriollo(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Otras
                  </label>
                  <input
                    value={hembraotras}
                    onChange={(e) => {
                      setHembraotras(e.target.value);
                    }}
                    placeholder="Introducir datos"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            {/* //! ¿Cuál es el destino de la mayor parte de la producion ovino? */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                11.4.¿Cuál es el destino comercial de sus ovinos?
              </span>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventaovinomercadopublico}
                      onChange={() => {
                        setVentaovinomercadopublico(!ventaovinomercadopublico);
                      }}
                      id="checkbox7"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox7"
                      className="uppercase ml-2 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Venta al público (mercado)
                    </label>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      value={ventaovinomercadopublicocosto}
                      onChange={(e) => {
                        setVentaovinomercadopublicocosto(e.target.value);
                      }}
                      placeholder="soles"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventacarnicero}
                      onChange={() => {
                        setVentacarnicero(!ventacarnicero);
                      }}
                      id="checkbox8"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />

                    <label
                      htmlFor="checkbox8"
                      className="uppercase ml-6 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Venta a carniceros
                    </label>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      value={ventacarnicerocosto}
                      onChange={(e) => {
                        setVentacarnicerocosto(e.target.value);
                      }}
                      placeholder="soles"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventaovinootros}
                      onChange={() => {
                        setVentaovinootros(!ventaovinootros);
                      }}
                      id="checkbox9"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label
                      htmlFor="checkbox9"
                      className="uppercase ml-5 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      otros
                    </label>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      value={ventaovinootroscosto}
                      onChange={(e) => {
                        setVentaovinootroscosto(e.target.value);
                      }}
                      placeholder="soles"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex mb-1">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="flex items-center mb-4">
                    <input
                      checked={ventaovinoauto}
                      onChange={() => {
                        setVentaovinoauto(!ventaovinoauto);
                      }}
                      id="checkbox10"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    ></input>

                    <label
                      htmlFor="checkbox10"
                      className="uppercase ml-5 text-xs font-bold text-blueGray-600 font-bold"
                    >
                      Autoconsumo
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* //? X. ANIMALES MENORES*/}
            <hr className="mt-6 border-b-1 border-gray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              XII. ¿QUE ANIMALES MENORES CRIA Ud.?
            </h6>
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Pollos de engorde
                  </label>
                  <input
                    value={menorespolloengorde}
                    onChange={(e) => {
                      setMenorespolloengorde(e.target.value);
                    }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Gallinas
                  </label>
                  <input
                    value={menoresgallina}
                    onChange={(e) => {
                      setMenoresgallina(e.target.value);
                    }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Porcinos
                  </label>
                  <input
                    value={menoresporcinos}
                    onChange={(e) => {
                      setMenoresporcinos(e.target.value);
                    }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Llamas
                  </label>
                  <input
                    value={menoresllamas}
                    onChange={(e) => {
                      setMenoresllamas(e.target.value);
                    }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Alpacas
                  </label>
                  <input
                    value={menoresalpaca}
                    onChange={(e) => {
                      setMenoresalpaca(e.target.value);
                    }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Caprinos
                  </label>
                  <input
                    value={menorescaprinos}
                    onChange={(e) => {
                      setMenorescaprinos(e.target.value);
                    }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Cuyes
                  </label>
                  <input
                    value={menorescuyes}
                    onChange={(e) => {
                      setMenorescuyes(e.target.value);
                    }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              {/* <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Total
                  </label>
                  <input
                    // value={menorescanes}
                    // onChange={(e) => {
                    //   setMenorescanes(e.target.value);
                    // }}
                    placeholder="Total"
                    type="text"
                    className="border-0 px-1 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div> */}
            </div>

            {/* //? XII.CAPACITACION*/}
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blue-900 text-sm mt-3 mb-6 font-bold uppercase">
              XII.CAPACITACION
            </h6>
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                ¿Pertenece a alguna asociacion, comité o cooperativa?
              </span>

              <select
                value={perteneceasociacioncooperativa}
                onChange={(e) => {
                  setPerteneceasociacioncooperativa(e.target.value);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Seleccione una opción</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>

              <div className="w-full mt-2">
                <input
                  value={cualasociacioncooperativa}
                  onChange={(e) => {
                    setCualasociacioncooperativa(e.target.value);
                  }}
                  placeholder="¿Cuál?"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>

            {/* //! En la cadena de valor de leche ¿ De que institucion recibe capacitacion/ asistencia tecnica? */}
            <div className="flex flex-wrap border mb-2  border-sky-500 p-3 rounded ">
              <span className="w-full mb-3 text-blue-500 font-bold">
                12.2. ¿ De que institucion recibe capacitacion/ asistencia
                tecnica?
              </span>
              <select
                value={institucioncapacita}
                onChange={(e) => {
                  setInstitucioncapacita(e.target.value);
                }}
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Seleccione una opción</option>
                <option value="ONG">ONG</option>
                <option value="Empresa privada">Empresa privada</option>
                <option value="SENASA">SENASA</option>
                <option value="INIA">INIA</option>
                <option value="Agencia agraria">Agencia agraria</option>
                <option value="Empresa minera">Empresa minera</option>
                <option value="CARE">CARE</option>
                <option value="FONCODES">FONCODES</option>
                <option value="MINAGRI">MINAGRI</option>
                <option value="Siembra y selva exportadora">
                  Siembra y selva exportadora
                </option>
                <option value="Agrorural">Agrorural</option>
                <option value="PEBLT">PEBLT</option>
                <option value="Direccion regional agraria">
                  Direccion regional agraria
                </option>
                <option value="Proyecto pradera">Proyecto pradera</option>
                <option value="Municipalidad Distrital">Municipalidad</option>
                <option value="Ninguno">Ninguno</option>
              </select>
            </div>

            {/* //? XIII. OPINION DEL PRODUCTORES */}

            <input
              type="submit"
              value="Actualizar"
              className="text-white bg-green-600 hover:bg-green-800  focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-10 py-2.5 text-center"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Edit;
