import { useState, useEffect } from "react";
import { collection, doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import logo from "../images/logo.png";

const InfraTernejare = () => {
  //! DB firestore
  const productoreCollection = collection(db, "basedsaman");

  const [count23, setCount23] = useState(0);
  const [countNo23, setCountNo23] = useState(0);

  //! spinner
  const [isSaving, setIsSaving] = useState(false);
  const [savingProductId, setSavingProductId] = useState(null);

  const [isSaving4, setIsSaving4] = useState(false);
  const [savingProductId4, setSavingProductId4] = useState(null);

  const [isSaving5, setIsSaving5] = useState(false);
  const [savingProductId5, setSavingProductId5] = useState(null);

  const [isSaving6, setIsSaving6] = useState(false);
  const [savingProductId6, setSavingProductId6] = useState(null);

  //! hooks
  const [productores, setProductores] = useState([]);
  const [productoresFiltrados, setProductoresFiltrados] = useState([]);

  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [rangoEdad, setRangoEdad] = useState("");
  const [terneraje, setTerneraje] = useState("");
  const [comedero, setComedero] = useState("");
  const [bebedero, setBebedero] = useState("");
  const [cobertizo, setCobertizo] = useState("");
  const [heniles, setHeniles] = useState("");
  const [numeroVacas, setNumeroVacas] = useState(0);
  const [hectareas, setHectareas] = useState("");
  const [centroPoblado, setCentroPoblado] = useState("");
  const [comunidad, setComunidad] = useState([]);
  const [apoyo23Terneraje, setApoyo23Terneraje] = useState(0);

  const [selectedComunidad, setSelectedComunidad] = useState("");

  // ! hooks print
  const [imprimirT, setImprimirT] = useState("general");

  //! mostrar docs
  const getProductores = () => {
    const unsubscribe = onSnapshot(productoreCollection, (querySnapshot) => {
      setProductores(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    });
    return unsubscribe;
  };

  //! useEffect
  useEffect(() => {
    getProductores();
  }, []);

  const handlecentroPobladoChange = (event) => {
    const selectedCentroPoblado = event.target.value;
    setCentroPoblado(selectedCentroPoblado);

    if (selectedCentroPoblado !== "") {
      switch (selectedCentroPoblado) {
        case "CP CHUCARIPO":
          setComunidad([
            "RINCONADA CARIGUITA",
            "PAMPA CARIGUITA",
            "PAMPA CHUCARIPO",
            "LITERO",
            "JASANA CHICO",
            "HOMBRE HUATY",
            "HIRUITO",
            "CCORPA",
            "CANTAGACHI",
            "ALTO FURUNCHA",
          ]);
          break;
        case "ZONA CENTRO":
          setComunidad([
            "RINCONADA SAMAN",
            "MUNI CHICO",
            "MACHACA ISLA",
            "ISLA SAMAN",
            "DESVIO SAMAN",
            "COLLINCHA QUINCHARAPI",
            "CHILCHE",
            "CCORPA SAMAN",
            "KANCOLLA MACHA",
          ]);
          break;
        case "CP QUEJON MOCCO":
          setComunidad([
            "CHEJACHI",
            "SANTA CLARA",
            "TAMBO",
            "1° HUCUATA",
            "QUEJON VALLE",
            "2° HUCUATA",
            "KAPALLIA",
            "LLATHA MOCCO",
          ]);
          break;
        case "CP MUNI GRANDE":
          setComunidad([
            "TITIHUARIJA",
            "MUNI PAMPA",
            "MUNI SALINAS",
            "JERGACHI",
            "YCALLO",
          ]);
          break;
        case "CP JASANA GRANDE":
          setComunidad([
            "CARPI PAMPA",
            "ESQUELE",
            "ISLA",
            "PATALLA",
            "ISILLOA",
          ]);
          break;
          case "CP SAN JUAN DE CHACAMARCA":
            setComunidad(["PRIMER CHACAMARCA", "SEGUNDO CHACAMARCA"]);
          break;
        default:
          setComunidad([]);
      }
    }
  };

  const sumaVacasPorProductor = productoresFiltrados.map((productor) => {
    const resultado =
      Number(productor.ntotalvacunobrownswiss) +
      Number(productor.ntotalvacunoholstein) +
      Number(productor.ntotalvacunootraraza);
    return { productorId: productor.id, sumaVacas: resultado };
  });

  //! filtrar productores según el término de búsqueda
  useEffect(() => {
    let filteredProductores = productores;

    if (centroPoblado !== "") {
      filteredProductores = filteredProductores.filter(
        (productor) => productor.centropoblado === centroPoblado
      );
    }

    if (selectedComunidad !== "") {
      filteredProductores = filteredProductores.filter(
        (productor) => productor.comunidadsector === selectedComunidad
      );
    }

    if (terminoBusqueda !== "") {
      filteredProductores = filteredProductores.filter(
        (productor) =>
          productor.dni.toString().includes(terminoBusqueda) ||
          (productor.nombre + " " + productor.apellido.toString())
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
      );
    }

    if (rangoEdad === "mayores_o_iguales") {
      filteredProductores = filteredProductores.filter(
        (productor) => productor.edad >= 65
      );
    } else if (rangoEdad === "menores") {
      filteredProductores = filteredProductores.filter(
        (productor) => productor.edad < 65
      );
    }

    // if (terneraje !== "") {
    //   const hasTerneraje = terneraje === "true";
    //   filteredProductores = filteredProductores.filter(
    //     (productor) => productor.terneraje === hasTerneraje
    //   );
    // }

    if (terneraje !== "") {
      filteredProductores = filteredProductores.filter((productor) => {
        if (terneraje === "true") {
          return productor.terneraje === true;
        } else if (terneraje === "false") {
          return productor.terneraje === false || productor.terneraje === "";
        }
        return false;
      });
    }

    if (comedero !== "") {
      filteredProductores = filteredProductores.filter((productor) => {
        if (comedero === "true") {
          return productor.comedero === true;
        } else if (comedero === "false") {
          return productor.comedero === false || productor.comedero === "";
        }
        return false;
      });
    }

    if (bebedero !== "") {
      filteredProductores = filteredProductores.filter((productor) => {
        if (bebedero === "true") {
          return productor.bebederos === true;
        } else if (bebedero === "false") {
          return productor.bebederos === false || productor.bebederos === "";
        }
        return false;
      });
    }

    if (cobertizo !== "") {
      filteredProductores = filteredProductores.filter((productor) => {
        if (cobertizo === "true") {
          return productor.cobertizos === true;
        } else if (cobertizo === "false") {
          return productor.cobertizos === false || productor.cobertizos === "";
        }
        return false;
      });
    }

    if (heniles !== "") {
      filteredProductores = filteredProductores.filter((productor) => {
        if (heniles === "true") {
          return productor.silosforraje === true;
        } else if (heniles === "false") {
          return (
            productor.silosforraje === false || productor.silosforraje === ""
          );
        }
        return false;
      });
    }

    if (numeroVacas !== null) {
      const numVacas = Number(numeroVacas);
      filteredProductores = filteredProductores.filter((productor) => {
        const numeroVacasProductor =
          Number(productor.ntotalvacunobrownswiss) +
          Number(productor.ntotalvacunoholstein) +
          Number(productor.ntotalvacunootraraza);
        return numeroVacasProductor >= numVacas;
      });
    }

    if (hectareas !== null) {
      const numHectare = Number(hectareas);
      filteredProductores = filteredProductores.filter((productor) => {
        if (typeof productor.totaldehectareas === "number") {
          return productor.totaldehectareas >= numHectare;
        } else if (typeof productor.totaldehectareas === "string") {
          return parseFloat(productor.totaldehectareas) >= numHectare;
        } else if (
          productor.totaldehectareas instanceof firebase.firestore.Timestamp
        ) {
          return parseFloat(productor.totaldehectareas.seconds) >= numHectare;
        } else {
          return false;
        }
      });
    }

    if (apoyo23Terneraje !== null) {
      filteredProductores = filteredProductores.filter((productor) => {
        if (apoyo23Terneraje === "true") {
          return productor.asistenciatresTerneraje === true;
        }
        return true;
      });
    }

    setProductoresFiltrados(filteredProductores);
  }, [
    productores,
    centroPoblado,
    terminoBusqueda,
    rangoEdad,
    terneraje,
    comedero,
    bebedero,
    cobertizo,
    heniles,
    numeroVacas,
    hectareas,
    apoyo23Terneraje,
    selectedComunidad,
  ]);

  // Función para actualizar el campo de "apoyo asistencia 2023" en  Firestore
  const handleCheck = async (id, asistenciatresTerneraje) => {
    setSavingProductId(id);
    setIsSaving(true);
    // Actualizar arrays de "productores" y "filteredProductores"
    const productorIndex = productores.findIndex((p) => p.id === id);
    if (productorIndex > -1) {
      productores[productorIndex].asistenciatresTerneraje =
        asistenciatresTerneraje;
      setProductores([...productores]);
    }
    const filteredProductorIndex = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex > -1) {
      productoresFiltrados[filteredProductorIndex].asistenciatresTerneraje =
        asistenciatresTerneraje;
      setProductoresFiltrados([...productoresFiltrados]);
    }

    // Actualizar campo en Firestore
    const productorRef = doc(productoreCollection, id);
    await updateDoc(productorRef, { asistenciatresTerneraje });

    // Contar el número de productores con check
    const countTres = productoresFiltrados.filter(
      (p) => p.asistenciatresTerneraje
    ).length;
    setCount23(countTres);
    // console.log(`Número de productores con asistencia en 2023: ${countTres}`);
    const countNoTres = productoresFiltrados.filter(
      (p) => !p.asistenciatresTerneraje
    ).length;
    setCountNo23(countNoTres);

    setSavingProductId(null);
    setIsSaving(false);
  };

  useEffect(() => {
    const countTres = productoresFiltrados.filter(
      (p) => p.asistenciatresTerneraje
    ).length;
    setCount23(countTres);
    const countNoTres = productoresFiltrados.filter(
      (p) => !p.asistenciatresTerneraje
    ).length;
    setCountNo23(countNoTres);
  }, [productoresFiltrados]);

  const handleCheck4 = async (id, apoyo2024Terneraje) => {
    setSavingProductId4(id);
    setIsSaving4(true);
    const productorIndex4 = productores.findIndex((p) => p.id === id);
    if (productorIndex4 > -1) {
      productores[productorIndex4].apoyo2024Terneraje = apoyo2024Terneraje;
      setProductores([...productores]);
    }
    const filteredProductorIndex4 = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex4 > -1) {
      productoresFiltrados[filteredProductorIndex4].apoyo2024Terneraje =
        apoyo2024Terneraje;
      setProductoresFiltrados([...productoresFiltrados]);
    }

    // Actualizar campo en Firestore
    const productorRef4 = doc(productoreCollection, id);
    await updateDoc(productorRef4, { apoyo2024Terneraje });
    setSavingProductId4(null);
    setIsSaving4(false);
  };

  const handleCheck5 = async (id, apoyo2025Terneraje) => {
    setSavingProductId5(id);
    setIsSaving5(true);
    const productorIndex = productores.findIndex((p) => p.id === id);
    if (productorIndex > -1) {
      productores[productorIndex].apoyo2025Terneraje = apoyo2025Terneraje;
      setProductores([...productores]);
    }
    const filteredProductorIndex = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex > -1) {
      productoresFiltrados[filteredProductorIndex].apoyo2025Terneraje =
        apoyo2025Terneraje;
      setProductoresFiltrados([...productoresFiltrados]);
    }
    // Actualizar campo en Firestore
    const productorRef = doc(productoreCollection, id);
    await updateDoc(productorRef, { apoyo2025Terneraje });
    setSavingProductId5(null);
    setIsSaving5(false);
  };

  const handleCheck6 = async (id, apoyo2026Terneraje) => {
    setSavingProductId6(id);
    setIsSaving6(true);
    const productorIndex = productores.findIndex((p) => p.id === id);
    if (productorIndex > -1) {
      productores[productorIndex].apoyo2026Terneraje = apoyo2026Terneraje;
      setProductores([...productores]);
    }
    const filteredProductorIndex = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex > -1) {
      productoresFiltrados[filteredProductorIndex].apoyo2026Terneraje =
        apoyo2026Terneraje;
      setProductoresFiltrados([...productoresFiltrados]);
    }
    // Actualizar campo en Firestore
    const productorRef = doc(productoreCollection, id);
    await updateDoc(productorRef, { apoyo2026Terneraje });
    setSavingProductId6(null);
    setIsSaving6(false);
  };

  const handlePrint = () => {
    window.print();
  };

  //! vista del componente
  return (
    <div className="min-h-screen pb-5 bg-green-200">
      <h2 className="pt-8 mr-5 text-3xl font-black print:text-sm print:uppercase print:pt-1">
        Infraestructura {""}
        <span className="text-green-500 print:text-black">Comedero</span>
      </h2>
      <div className="hidden print:flex print:flex-row print:justify-end">
        <img src={logo} alt="logo" className="h-32 absolute top-0 right-7" />
      </div>
      <div className="flex justify-end mr-10 print:hidden">
        <Link
          to="/"
          className="bg-green-600 hover:bg-green-700 py-2 px-4 rounded-sm font-bold text-white uppercase hover:shadow-lg"
        >
          Regresar
        </Link>
      </div>
      <div className="overflow-hidden rounded-lg bg-green-100 shadow-md my-3 mx-10 print:my-2">
        <div className="flex flex-wrap mb-0 p-2 print:mb-0">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-2">
              <label
                htmlFor="centroPoblado"
                className="block mb-2 text-sm font-bold text-blue-800 print:hidden"
              >
                FILTRO POR CENTRO POBLADO
              </label>
              <select
                id="centroPoblado"
                className="border-2 border-green-600 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green-500 block w-full p-2.5 print:hidden"
                value={centroPoblado}
                onChange={handlecentroPobladoChange}
              >
                <option value="">Seleccione un Centro Poblado...</option>
                <option value="CP CHUCARIPO">CP CHUCARIPO</option>
                <option value="ZONA CENTRO">ZONA CENTRO</option>
                <option value="CP QUEJON MOCCO">CP QUEJON MOCCO</option>
                <option value="CP MUNI GRANDE">CP MUNI GRANDE</option>
                <option value="CP JASANA GRANDE">CP JASANA GRANDE</option>
                <option value="CP SAN JUAN DE CHACAMARCA">CP SAN JUAN DE CHACAMARCA</option>
              </select>
              <p className="text-sm text-left font-bold hidden print:block print:text-xs">
                CENTRO POBLADO DE:
                <span className="pl-3 font-bold print:uppercase text-gray-300">
                  {centroPoblado || "General"}
                </span>
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-2">
              <label
                htmlFor="comunidad"
                className="block mb-2 text-sm font-bold text-blue-800 print:hidden"
              >
                FILTRO POR COMUNIDAD
              </label>
              {comunidad.length > 0 && (
                <select
                  id="comunidad"
                  className="border-2 border-green-600 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green-500 block w-full p-2.5  print:hidden"
                  value={selectedComunidad}
                  onChange={(e) => setSelectedComunidad(e.target.value)}
                >
                  <option value="">Seleccione una Comunidad...</option>
                  {comunidad.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              )}
              <p className="text-sm text-left  font-bold hidden print:block print:text-xs">
                COMUNIDAD DE:{" "}
                <span className="pl-3 font-bold text-gray-300 print:uppercase">
                  {selectedComunidad || "General"}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="mb-3.5 print:hidden print:mb-0">
          <div className="relative mx-5">
            <input
              value={terminoBusqueda}
              onChange={(e) => setTerminoBusqueda(e.target.value)}
              type="search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border-2 border-green-600 rounded-lg focus:outline-none focus:border-green-500"
              placeholder="Buscar productor por nombre..."
            />
          </div>
        </div>

        <div className="flex flex-wrap mx-5 my-3 print:hidden print:mb-0">
          <div className="px-4">
            <label
              htmlFor="filtro-cobertizos"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro Terneraje
            </label>
            <select
              id="filtro-cobertizos"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={terneraje}
              onChange={(e) => setTerneraje(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>

          <div className="px-4">
            <label
              htmlFor="filtro-cobertizos"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro Comedero
            </label>
            <select
              id="filtro-cobertizos"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={comedero}
              onChange={(e) => setComedero(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>

          <div className="px-4">
            <label
              htmlFor="filtro-cobertizos"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro Bebedero
            </label>
            <select
              id="filtro-cobertizos"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={bebedero}
              onChange={(e) => setBebedero(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>

          <div className="px-4">
            <label
              htmlFor="filtro-cobertizos"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro cobertizo
            </label>
            <select
              id="filtro-cobertizos"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={cobertizo}
              onChange={(e) => setCobertizo(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>

          <div className="px-4 ">
            <label
              htmlFor="filtro-cobertizos"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro Heniles
            </label>
            <select
              id="filtro-cobertizos"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={heniles}
              onChange={(e) => setHeniles(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </div>

          <div className="px-4">
            <label
              htmlFor="filtro-vacas"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro N° de Vacas
            </label>
            <select
              id="filtro-vacas"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={numeroVacas || ""}
              onChange={(e) =>
                setNumeroVacas(e.target.value !== "" ? e.target.value : null)
              }
            >
              <option value="">-- Seleccione --</option>
              <option value="2">Mayor o Igual a 2</option>
              <option value="3">Mayor o Igual a 3</option>
              <option value="4">Mayor o Igual a 4</option>
              <option value="6">Mayor o Igual a 6</option>
              <option value="6">Mayor o Igual a 6</option>
              <option value="7">Mayor o Igual a 7</option>
              <option value="10">Mayor o Igual a 10</option>
            </select>
          </div>

          <div className="px-4">
            <label
              htmlFor="filtro-edad"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro Edad
            </label>
            <select
              id="filtro-edad"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={rangoEdad}
              onChange={(e) => setRangoEdad(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="mayores_o_iguales">Mayores o iguales a 65</option>
              <option value="menores">Menores o iguales a 64</option>
            </select>
          </div>

          <div className="px-4">
            <label
              htmlFor="filtro-hectareas"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro Total Hectáreas
            </label>
            <select
              id="filtro-hectareas"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={hectareas}
              onChange={(e) => setHectareas(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="0.5">Mayor o igual a 0.5</option>
              <option value="1">Mayor o igual a 1</option>
              <option value="2">Mayor o igual a 2</option>
              <option value="3">Mayor o igual a 3</option>
              <option value="4">Mayor o igual a 4</option>
              <option value="5">Mayor o igual a 5</option>
              <option value="8">Mayor o igual a 8</option>
              <option value="10">Mayor o igual a 10</option>
              <option value="15">Mayor o igual a 15</option>
            </select>
          </div>
          <div className="px-4">
            <label
              htmlFor="filtro-apoyo23"
              className="mb-1 block uppercase font-bold text-xs text-blue-800"
            >
              Filtro Apoyo 2023
            </label>
            <select
              id="filtro-apoyo23"
              className="border-2 border-amber-400 text-sm rounded-md focus:outline-none p-1 focus:border-amber-500"
              value={apoyo23Terneraje}
              onChange={(e) => setApoyo23Terneraje(e.target.value)}
            >
              <option value="">-- Seleccione --</option>
              <option value="true">Apoyo</option>
            </select>
          </div>
        </div>

        <div className="flex flex-row-reverse print:mt-0">
          <div className="w-1/2 flex flex-row justify-end">
            <div className="flex mr-6 print:hidden ">
              <ReactHTMLTableToExcel
                id="table-excel-btn"
                table="table-excel"
                filename="Reporte de apoyo 2023"
                sheet="hoja1"
                buttonText="Exportar a Excel"
                excludeColumns=".excelno"
                className="bg-green-700 px-6 py-2.5 mb-3 rounded-lg font-medium text-white hover:font-bold hover:shadow-2xl"
              />
            </div>
            <div className="flex  mr-6  print:hidden ">
              <button
                onClick={handlePrint}
                className="bg-black px-6 py-2.5 mb-3 rounded-lg font-medium text-white hover:font-bold hover:shadow-2xl "
              >
                Imprimir
              </button>
            </div>
            <div className="flex items-center  mr-6  print:hidden ">
              <label
                htmlFor="print"
                className="flex items-center mr-1 uppercase font-bold text-xs text-black"
              >
                Filtro imprimir:
              </label>
              <select
                id="print"
                className="border-2 py-1 border-gray-500 text-sm rounded-md focus:outline-none focus:border-black"
                value={imprimirT}
                onChange={(e) => setImprimirT(e.target.value)}
              >
                <option value="general">General</option>
                <option value="23">2023</option>
                <option value="24">2024</option>
                <option value="25">2025</option>
                <option value="26">2026</option>
              </select>
            </div>
          </div>

          <div className="w-1/2 flex flex-row ml-5">
            <div className="flex mr-6">
              <h4 className=" bg-green-50 border border-green-700 px-3 py-1.5 mb-3 rounded-lg shadow-md font-semibold text-lg text-green-900 print:text-xs print:shadow-none">
                Total apoyo 2023: {""}
                <span className="font-black text-lg text-black print:text-xs">
                  {count23}
                </span>
              </h4>
            </div>

            <div className="flex mr-6">
              <h4 className=" bg-red-50 border border-red-700 px-3 py-1.5 text-lg mb-3 rounded-lg font-semibold shadow-md text-red-900 print:text-xs print:shadow-none">
                Total no apoyo 2023: {""}
                <span className="font-black text-lg text-black print:text-xs">
                  {countNo23}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <table
          id="table-excel"
          className="w-full border-collapse bg-green-50 text-left text-sm text-gray-500 mt-0 page-break-after:avoid"
        >
          <thead className="bg-emerald-200">
            <tr className="text-center font-bold text-indigo-900  border border-sky-300 print:hidden">
              <th
                scope="col"
                className="py-4 border-r border-sky-300 print:border-gray-300 "
                colSpan="7"
              >
                DATOS GENERALES
              </th>
              <th
                scope="col"
                className="py-4 border-r border-sky-300 excelno"
                colSpan="5"
              >
                INFRAESTRUCTURA
              </th>
              <th scope="col" className=" py-4 font-bold" colSpan="5">
                AÑO DE EJECUCIÓN
              </th>
            </tr>
            <tr className="text-xs text-gray-900 border-b-2 border-sky-500 print:border-gray-400">
              <th className="hidden print:table-cell print:border-gray-300 print:text-xxxs print:pl-0.5 print:py-0">
                N°
              </th>
              <th
                scope="col"
                className="px-4 py-2 border-l border-sky-300 print:border-gray-300 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Nombre y Apellido
              </th>
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                DNI
              </th>
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Edad
              </th>
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Centro Poblado
              </th>
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Comunidad / Sector
              </th>
              <th scope="col" className="px-2 py-2 print:text-xxxs print:p-0">
                Total Hectáreas
              </th>
              <th
                scope="col"
                className="px-2 py-2  border-r border-sky-300 print:border-gray-300 print:text-xxxs  print:p-0"
              >
                N° de Vacas
              </th>
              <th
                scope="col"
                className="py-2 text-center font-medium border-r border-sky-300  print:border-gray-300 print:text-xxxs print:py-0 excelno"
              >
                Terneraje
              </th>
              <th
                scope="col"
                className="py-2 text-center font-medium border-r border-sky-300 print:hidden"
              >
                Comederos
              </th>
              <th
                scope="col"
                className="py-2 text-center font-medium border-r border-sky-300 print:hidden"
              >
                Bebederos
              </th>
              <th
                scope="col"
                className="py-2 text-center border-r border-sky-300 font-medium  print:border-gray-300 print:text-xxxs print:py-0 excelno excelno"
              >
                Cobetizos
              </th>
              <th
                scope="col"
                className="py-2 text-center border-r border-sky-300 font-medium  print:border-gray-300 print:text-xxxs print:py-0 excelno excelno"
              >
                Heniles
              </th>
              <th
                scope="col"
                className="py-2 text-center border-r border-sky-200 font-medium print:border-gray-300 print:text-xxxs  print:py-0  excelno"
              >
                Apoyo
              </th>
              <th
                scope="col"
                className={`px-2 py-2 text-center print:text-xxxs print:p-0.5  ${
                  imprimirT === "23" || imprimirT === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                2023
              </th>
              <th
                scope="col"
                className={`px-2 py-2 text-center print:text-xxxs print:p-0.5  ${
                  imprimirT === "24" || imprimirT === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                2024
              </th>
              <th
                scope="col"
                className={`px-2 py-2 text-center print:text-xxxs print:p-0.5  ${
                  imprimirT === "25" || imprimirT === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                2025
              </th>
              <th
                scope="col"
                className={`px-2  py-2  border-r border-sky-300 text-center print:border-gray-300 print:text-xxxs print:p-0.5 ${
                  imprimirT === "26" || imprimirT === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                2026
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-sky-200 border-t border-sky-200 print:border-gray-300 print:divide-none">
            {productores.length !== 0 ? (
              productoresFiltrados.map((productor, index) => (
                <tr key={productor.id} className="hover:bg-sky-100">
                  <td className="hidden print:text-center print:table-cell print:text-xxxs print:p-0">
                    {index + 1}
                  </td>
                  <td className="hidden print:table-cell print:text-xxxs print:p-0">{`${productor.nombre} ${productor.apellido}`}</td>
                  <td className="flex gap-2 px-4 py-3 font-normal text-gray-900 print:hidden">
                    <div className="text-sm print:text-xs ">
                      {productor.nombre.length
                        ? productor.nombre
                        : "Nombre no disponible"}
                      <div className="text-gray-400 print:text-x">
                        {productor.apellido.length ? productor.apellido : "..."}
                      </div>
                    </div>
                  </td>

                  <td className="px-2 py-3 text-xs print:text-xxs print:p-0">
                    {productor.dni}
                  </td>
                  <td className="px-2 py-3 text-xs print:text-xxs print:p-0 print:text-center">
                    {productor.edad}
                  </td>

                  <td className="px-2 py-3 text-xs print:text-xxs print:p-0">
                    {productor.centropoblado}
                  </td>
                  <td className="px-2 py-3 text-x print:text-xxs print:p-0">
                    {productor.comunidadsector}
                  </td>
                  <td className="px-2 py-3 text-center text-x print:text-xxs print:p-0">
                    {+productor.totaldehectareas
                      ? +productor.totaldehectareas
                      : 0}
                  </td>
                  <td className="px-2 py-3 text-center text-xs border-r border-sky-200 print:border-gray-300 print:text-xxxs print:p-0">
                    {
                      sumaVacasPorProductor.find(
                        (item) => item.productorId === productor.id
                      )?.sumaVacas
                    }
                  </td>

                  <td className="text-center py-3 border-r border-sky-200 td-no print:border-gray-300 print:text-xxxs print:p-0 excelno excelno">
                    {" "}
                    {productor.terneraje ? (
                      <span className="text-blue-500">Si</span>
                    ) : (
                      <span className="text-red-500">No</span>
                    )}{" "}
                  </td>

                  <td className="text-center py-3 border-r border-sky-200 td-no print:hidden excelno">
                    {" "}
                    {productor.comedero ? (
                      <span className="text-blue-500">Si</span>
                    ) : (
                      <span className="text-red-500">No</span>
                    )}{" "}
                  </td>

                  <td className="text-center py-3 border-r border-sky-200 td-no print:hidden excelno">
                    {" "}
                    {productor.bebederos ? (
                      <span className="text-blue-500">Si</span>
                    ) : (
                      <span className="text-red-500">No</span>
                    )}{" "}
                  </td>

                  <td className="text-center py-3 border-r border-sky-200 td-no print:border-gray-300 print:text-xxxs print:p-0 excelno excelno">
                    {" "}
                    {productor.cobertizos ? (
                      <span className="text-blue-500">Si</span>
                    ) : (
                      <span className="text-red-500">No</span>
                    )}{" "}
                  </td>

                  <td className="text-center py-3 border-r border-sky-200 td-no print:border-gray-300 print:text-xxxs print:p-0 excelno excelno">
                    {" "}
                    {productor.silosforraje ? (
                      <span className="text-blue-500">Si</span>
                    ) : (
                      <span className="text-red-500">No</span>
                    )}{" "}
                  </td>

                  <td className="items-center border-r border-sky-200 print:border-gray-300 print:text-xxxs print:p-0 excelno">
                    <span className="flex items-center justify-center">
                      {productor.asistenciatresCobertizos ||
                      productor.apoyo2024Cobertizos ||
                      productor.apoyo2025Cobertizos ||
                      productor.apoyo2026Cobertizos ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-home-eco inline"
                          width="19"
                          height="19"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#ff9300"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h5" />
                          <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.325 0 .631 .077 .902 .215" />
                          <path d="M16 22s0 -2 3 -4" />
                          <path d="M19 21a3 3 0 0 1 0 -6h3v3a3 3 0 0 1 -3 3z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-minus inline"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#000000"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 12l14 0" />
                        </svg>
                      )}
                      {productor.asistenciatreseniles ||
                      productor.apoyo2024eniles ||
                      productor.apoyo2025eniles ||
                      productor.apoyo2026eniles ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-leaf inline "
                          width="19"
                          height="19"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#00b341"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 21c.5 -4.5 2.5 -8 7 -10" />
                          <path d="M9 18c6.218 0 10.5 -3.288 11 -12v-2h-4.014c-9 0 -11.986 4 -12 9c0 1 0 3 2 5h3z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-minus inline"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#000000"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 12l14 0" />
                        </svg>
                      )}
                    </span>
                  </td>

                  <td
                    className={`text-center py-3 print:p-0 ${
                      imprimirT === "23" || imprimirT === "general"
                        ? ""
                        : "print:hidden"
                    }
`}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.asistenciatresCobertizos ||
                        productor.asistenciatreseniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.asistenciatresTerneraje || false}
                      onChange={(e) => {
                        handleCheck(productor.id, e.target.checked);
                      }}
                      disabled={
                        productor.asistenciatresCobertizos ||
                        productor.asistenciatreseniles
                      }
                    />
                    {savingProductId === productor.id && isSaving && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>
                  <td
                    className={`text-center py-3 print:p-0 ${
                      imprimirT === "24" || imprimirT === "general"
                        ? ""
                        : "print:hidden"
                    }
`}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.apoyo2024Cobertizos ||
                        productor.apoyo2024eniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.apoyo2024Terneraje || false}
                      onChange={(e) =>
                        handleCheck4(productor.id, e.target.checked)
                      }
                      disabled={
                        productor.apoyo2024Cobertizos ||
                        productor.apoyo2024eniles
                      }
                    />
                    {savingProductId4 === productor.id && isSaving4 && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>
                  <td
                    className={`text-center py-3 print:p-0 ${
                      imprimirT === "25" || imprimirT === "general"
                        ? ""
                        : "print:hidden"
                    }
`}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.apoyo2025Cobertizos ||
                        productor.apoyo2025eniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.apoyo2025Terneraje || false}
                      onChange={(e) =>
                        handleCheck5(productor.id, e.target.checked)
                      }
                      disabled={
                        productor.apoyo2025Cobertizos ||
                        productor.apoyo2025eniles
                      }
                    />
                    {savingProductId5 === productor.id && isSaving5 && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>
                  <td
                    className={`text-center py-3 print:p-0 ${
                      imprimirT === "26" || imprimirT === "general"
                        ? ""
                        : "print:hidden"
                    }
`}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.apoyo2026Cobertizos ||
                        productor.apoyo2026eniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.apoyo2026Terneraje || false}
                      onChange={(e) =>
                        handleCheck6(productor.id, e.target.checked)
                      }
                      disabled={
                        productor.apoyo2026Cobertizos ||
                        productor.apoyo2026eniles
                      }
                    />
                    {savingProductId6 === productor.id && isSaving6 && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="11"
                  className="grid mx-[120%] my-8 w-full place-items-center"
                  role="status"
                >
                  <svg
                    aria-hidden="true"
                    className="inline w-14 h-14 mr-2 text-gray-200 animate-spin fill-red-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="page-number"></div>
      </div>
    </div>
  );
};

export default InfraTernejare;
