import { useState } from "react";
import mossholder from "../images/samanescudo.png";
import { auth } from "../firebase/firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";


const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const signIn = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      console.log(userCredential);
    }).catch((error) => {
      // console.log(error);
      setError(error.message);
    })
  };

  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) navigate("/");
  } )

  return (
    <div className="w-full h-screen flex items-start bg-amber-100" >
      <div className="relative w-1/2 h-full flex flex-col">
        
        <div className="hidden sm:flex justify-center items-center h-3/4 pt-16">
  <img
    className="h-full"
    src={mossholder}
    alt="imagen"
  />
  
 
</div>
<div  className="hidden sm:flex justify-center items-start h-1/4">
      <p className="text-center text-3xl font-bold">Gestion 2023 - 2026</p>

    </div>
    <div class="pt-8 pb-2">
								<h1 class="text-2xl font-bold text-gray-900 dark:text-white">Freddy Anthony Yana Ccaccasaca</h1>
                 
								<p class="text-sm mt-2 text-gray-600 dark:text-gray-400">ALCALDE DE LA MUNICIPALIDAD DISTRIRAL DE SAMAN
								</p>
								<div class="text-gray-600 dark:text-gray-400 text-xs md:text-sm">
									<p class="mt-2">
										
									</p>
								</div>
                </div>

    <div class="text-center">
    <p class="text-xs text-gray-600 text-center mt-8">&copy; 2023 Goduz-tec</p>
      </div>


      </div>

      <div className="w-1/2 h-full bg-emerald-100 flex flex-col p-20">
        <div className="w-full flex flex-col mt-10">
          <div className="w-full flex flex-col mb-6">
            <h3 className="text-3xl font-semibold mb-4">Login</h3>
            <p className="text-base mb-2">
              ¡Bienvenido de nuevo! Por favor ingrese sus datos.
            </p>
            
            
          </div>
         


          <form onSubmit={signIn} action="" class="my-10">
            <div class="flex flex-col space-y-5">
                <label for="email">
                    <p class="font-medium text-slate-700 pb-2">Email </p>
                    <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                     id="email"
                     name="email" 
                     type="email" 
                     class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                      placeholder="Ingrese su  email "
                      required/>
                </label>
                <label for="password">
                    <p class="font-medium text-slate-700 pb-2">Contraseña</p>
                    <input 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                     name="password" 
                     type="password" 
                     class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" 
                     placeholder="Ingrese su Contraseña"/>
                </label>
                
                <button
                 type="submit"
                class="w-full py-3 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                      </svg>
                      <span> Iniciar sesión</span>
                </button>
                {error && ( 
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">El Usuario no esta registrado!/ error contraseña</strong>
                <span className="block sm:inline"> {error}</span>
              </div>
             )}

                
            </div>
        </form>

        </div>
      </div>
    </div>
  );
};

export default LoginPage;