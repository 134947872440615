import { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../css/print.css";
import { Link } from "react-router-dom";

const ReporteVacuno = () => {
  //! DB firestore
  const productoreCollection = collection(db, "basedsaman");

  //! hooks
  //! hooks
  const [productores, setProductores] = useState([]);
  const [productoresFiltrados, setProductoresFiltrados] = useState([]);

  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [centroPoblado, setCentroPoblado] = useState("");
  const [comunidad, setComunidad] = useState([]);

  const [selectedComunidad, setSelectedComunidad] = useState("");

  //! mostrar docs
  const getProductores = () => {
    const unsubscribe = onSnapshot(productoreCollection, (querySnapshot) => {
      setProductores(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    });
    return unsubscribe;
  };

  //! useEffect
  useEffect(() => {
    getProductores();
  }, []);

  const handlecentroPobladoChange = (event) => {
    const selectedCentroPoblado = event.target.value;
    setCentroPoblado(selectedCentroPoblado);

    if (selectedCentroPoblado !== "") {
      switch (selectedCentroPoblado) {
        case "CP CHUCARIPO":
          setComunidad([
            "RINCONADA CARIGUITA",
            "PAPA CARIGUITA",
            "PAMPA CHUCARIPO",
            "LITERO",
            "JASANA CHICO",
            "HOMBREHUATY",
            "HIRUITO",
            "CCORPA",
            "CANTAGACHI",
            "ALTO FURUNCHA",
          ]);
          break;
        case "ZONA CENTRO":
          setComunidad([
            "RINCONADA SAMAN",
            "RINCONADA SAMÀN",
            "MUNICHICO",
            "MACHACA ISLA",
            "ISLA SAMAN",
            "DESVIO SAMAN",
            "COLLINCHA QUINCHARAPI",
            "CHILCHE",
            "CCORPA SAMAN",
            "CANCOLLA MACHA",
          ]);
          break;
        case "CP QUEJON MOCCO":
          setComunidad([
            "CHEJACHI",
            "SANTA CLARA",
            "TAMBO",
            "1° HUCUATA",
            "QUEJON VALLE",
            "2° HUCUATA",
            "KAPALLIA",
            "LLATHA MOCCO",
          ]);
          break;
        case "CP MUNI GRANDE":
          setComunidad([
            "TITIHUARIJA",
            "MUNI PAMPA",
            "MUNI SALINAS",
            "JERGACHI",
            "ICALLO",
          ]);
          break;
        case "CP JASANA GRANDE":
          setComunidad([
            "CARPI PAMPA",
            "ESQUELE",
            "ISLA",
            "PATALLA",
            "ISILLOA",
          ]);
          break;
        case "CP CHACAMARCA":
          setComunidad(["CHACAMARCA I", "CHACAMARCA II"]);
          break;
        default:
          setComunidad([]);
      }
    }
  };

  //! filtrar productores según el término de búsqueda
  useEffect(() => {
    let filteredProductores = productores;

    if (centroPoblado !== "") {
      filteredProductores = filteredProductores.filter(
        (productor) => productor.centropoblado === centroPoblado
      );
    }

    if (selectedComunidad !== "") {
      filteredProductores = filteredProductores.filter(
        (productor) => productor.comunidadsector === selectedComunidad
      );
    }

    if (terminoBusqueda !== "") {
      filteredProductores = filteredProductores.filter(
        (productor) =>
          productor.dni.toString().includes(terminoBusqueda) ||
          (productor.nombre + " " + productor.apellido.toString())
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
      );
    }

    setProductoresFiltrados(filteredProductores);
  }, [productores, centroPoblado, terminoBusqueda, selectedComunidad]);

  const sumarVacas = (brownswiss, criollo, holstein, otraraza) => {
    return (
      Number(brownswiss) + Number(criollo) + Number(holstein) + Number(otraraza)
    );
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="min-h-screen pb-5 bg-green-200">
      <h2 className="pt-8 mr-5 text-3xl font-black print:text-sm print:uppercase print:pt-1">
        Reporte {""}
        <span className="text-green-500">Vacuno</span>
      </h2>
      <div className="flex justify-end mr-10 print:hidden">
        <Link
          to="/"
          className="bg-green-600 hover:bg-green-700 py-2 px-4 rounded-sm font-bold text-white uppercase hover:shadow-lg"
        >
          Regresar
        </Link>
      </div>
      <div className="overflow-hidden rounded-lg bg-green-100 shadow-md my-3 mx-10 print:my-2">
        <div className="flex flex-wrap mb-0 p-2 print:mb-0">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-2">
              <label
                htmlFor="centroPoblado"
                className="block mb-2 text-sm font-bold text-blue-800 print:hidden"
              >
                FILTRO POR CENTRO POBLADO
              </label>
              <select
                id="centroPoblado"
                className="border-2 border-green-600 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green-500 block w-full p-2.5 print:hidden"
                value={centroPoblado}
                onChange={handlecentroPobladoChange}
              >
                <option value="">Seleccione un Centro Poblado...</option>
                <option value="CP CHUCARIPO">CP CHUCARIPO</option>
                <option value="ZONA CENTRO">ZONA CENTRO</option>
                <option value="CP QUEJON MOCCO">CP QUEJON MOCCO</option>
                <option value="CP MUNI GRANDE">CP MUNI GRANDE</option>
                <option value="CP JASANA GRANDE">CP JASANA GRANDE</option>
                <option value="CP CHACAMARCA">CP CHACAMARCA</option>
              </select>
              <p className="text-sm text-left font-bold hidden print:block print:text-xs">
                CENTRO POBLADO DE:
                <span className="pl-3 font-bold print:uppercase text-gray-300">
                  {centroPoblado || "General"}
                </span>
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-2">
              <label
                htmlFor="comunidad"
                className="block mb-2 text-sm font-bold text-blue-800 print:hidden"
              >
                FILTRO POR COMUNIDAD
              </label>
              {comunidad.length > 0 && (
                <select
                  id="comunidad"
                  className="border-2 border-green-600 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green-500 block w-full p-2.5  print:hidden"
                  value={selectedComunidad}
                  onChange={(e) => setSelectedComunidad(e.target.value)}
                >
                  <option value="">Seleccione una Comunidad...</option>
                  {comunidad.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              )}
              <p className="text-sm text-left  font-bold hidden print:block print:text-xs">
                COMUNIDAD DE:{" "}
                <span className="pl-3 font-bold text-gray-300 print:uppercase">
                  {selectedComunidad || "General"}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="mb-3 print:hidden">
          <div className="relative mx-5">
            <input
              type="search"
              className="block w-full p-3.5 pl-10 text-sm text-gray-900 border-2 border-green-600 rounded-lg focus:outline-none focus:border-green-500"
              placeholder="Buscar productor por nombre..."
              value={terminoBusqueda}
              onChange={(e) => setTerminoBusqueda(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-row-reverse">
          <div className="w-1/2 flex flex-row justify-end">
            <div className="flex mr-6 print:hidden ">
              <ReactHTMLTableToExcel
                id="table-excel-btn"
                table="table-excel"
                filename="Reporte de apoyo 2023"
                sheet="hoja1"
                buttonText="Exportar a Excel"
                excludeColumns=".excelno"
                className="bg-green-700 px-6 py-2.5 mb-3 rounded-lg font-medium text-white hover:font-bold hover:shadow-2xl"
              />
            </div>
            <div className="flex  mr-6  print:hidden ">
              <button
                onClick={handlePrint}
                className="bg-black px-6 py-2.5 mb-3 rounded-lg font-medium text-white hover:font-bold hover:shadow-2xl "
              >
                Imprimir
              </button>
            </div>
          </div>
        </div>

        <table
          id="table-excel"
          className="w-full border-collapse bg-green-50 text-left text-sm text-gray-500 mt-2"
        >
          <thead className="bg-emerald-200">
            <tr className="text-center font-bold text-indigo-900  border border-sky-300 print:hidden">
              <th
                scope="col"
                className="py-4 border-r border-sky-300 print:border-gray-300 "
                colSpan="5"
              >
                DATOS GENERALES
              </th>
              <th
                scope="col"
                className="py-4 border-r border-sky-300 print:hidden  excelno"
                colSpan="4"
              >
                CATEGORIAS DE GANADO VACUNO
              </th>
              <th scope="col" className="py-4" colSpan="1">
                TOTAL DE VACUNOS
              </th>
            </tr>
            <tr className="text-xs text-gray-900 border-b-2 border-sky-500 print:border-gray-400">
            <th scope="col" className="px-4 py-2 border-l border-sky-300 print:border-gray-300 print:text-xs print:p-0.5">
                Nombre y Apellido
              </th>
              <th scope="col" className="px-2 py-2 print:text-xs print:p-0.5">
                DNI
              </th>
              <th scope="col" className="px-2 py-2 print:text-xs print:p-0.5">
                Edad
              </th>
              <th scope="col" className="px-2 py-2 print:text-xs print:p-0.5">
                Centro Poblado
              </th>
              <th scope="col" className="px-2 py-2 border-r border-sky-300 print:border-gray-300 print:text-xs print:p-0.5">
                Comunidad / Sector
              </th>

              <th scope="col" className="px-2 py-2 print:hidden excelno">
                Brownswiss
              </th>
              <th scope="col" className="px-2 py-2 print:hidden">
                Criollo
              </th>
              <th scope="col" className="px-2 py-2 print:hidden">
                Holstein
              </th>
              <th scope="col" className="px-2 py-2 border-r border-sky-300 print:hidden">
                Otra Raza
              </th>
              <th
                scope="col"
                className="px-2 py-2 border-r border-sky-300 text-center print:border-gray-300"
              >
                Total
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-sky-200 border-t border-sky-200 print:border-gray-300 print:divide-none">
            {productores.length !== 0 ? (
              productoresFiltrados.map((productor) => (
                <tr key={productor.id} className="hover:bg-sky-100">
                  <td className="flex gap-2 px-4 py-3 font-normal text-gray-900  print:p-1 print:border-t-0 print:border-r-0">
                    <div className="text-sm print:text-xs">
                     {productor.nombre.length ? (productor.nombre) : ("Nombre no disponible") }
                      <div className="text-gray-400 print:text-xs">{productor.apellido.length ? (productor.apellido) : ("...")}</div>
                    </div>
                  </td>

                  <td className="px-2 py-3 text-xs print:text-xs print:p-0.5">{productor.dni}</td>
                  <td className="px-2 py-3 text-xs print:text-xs print:p-0.5">{productor.edad}</td>

                  <td className="px-2 py-3 text-xs print:text-xs print:p-0.5">
                    {productor.centropoblado}
                  </td>
                  <td className="px-2 py-3 text-xs border-r border-sky-200 print:text-xs print:p-0.5 print:border-gray-300">
                    {productor.comunidadsector}
                  </td>

                  <td className="text-center py-3 td-no print:hidden">
                    {productor.ntotalvacunobrownswiss}
                  </td>
                  <td className=" text-center py-3 print:hidden">
                    {productor.ntotalvacunocriollo}
                  </td>
                  <td className="text-center py-3 print:hidden">
                    {productor.ntotalvacunoholstein}
                  </td>
                  <td className="text-center py-3 border-r border-sky-200 print:hidden">
                    {productor.ntotalvacunootraraza}
                  </td>
                  <td className=" py-3 text-center print:p-0.5">
                    {sumarVacas(
                      productor.ntotalvacunobrownswiss,
                      productor.ntotalvacunocriollo,
                      productor.ntotalvacunoholstein,
                      productor.ntotalvacunootraraza
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="11"
                  className="grid mx-[120%] my-8 w-full place-items-center"
                  role="status"
                >
                  <svg
                    aria-hidden="true"
                    className="inline w-14 h-14 mr-2 text-gray-200 animate-spin fill-red-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="page-number"></div>
      </div>
    </div>
  );
};

export default ReporteVacuno;
