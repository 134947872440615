import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, limit, query } from "firebase/firestore";
import { db } from "../firebase/firebase";

const ListadoBeneficiarios = () => {
  const [productores, setProductores] = useState([]);

  //! DB firestore
  const productoreCollection = collection(db, "basedsaman");

  //! mostrar docs
  const getProductores = async () => {
    // const data = await getDocs(productoreCollection);
    const data = await getDocs(query(productoreCollection, limit(30)));
    setProductores(data.docs.map((doct) => ({ ...doct.data(), id: doct.id })));
  };

 

  //! useEffect
  useEffect(() => {
    getProductores();
  }, []);

  return (
    <>
      <h1 className="text-3xl font-black mt-6">
        Listado de {""}
        <span className="text-lime-500">Beneficiarios</span>
      </h1>
      <div className="flex justify-end mr-11">
        <Link to='/' className="bg-green-500 py-2 px-4 uppercase font-semibold rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105">Regresar</Link>
      </div>
      <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md my-5 mx-10">
        <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
          <thead>
            <tr className="bg-slate-200 text-slate-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Nombre y Apellido</th>
              <th className="py-3 px-6 text-left">DNI</th>
              <th className="py-3 px-6 text-left">Centro Poblado</th>
              <th className="py-3 px-6 text-left">Comunidad/Sector</th>
              <th className="py-3 px-6 text-center">Estado</th>
              <th className="py-3 px-6 text-center">Acciones</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {productores.map((productor) => (
              <tr
                key={productor.id}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className=" py-3 px-6 text-left whitespace-nowrap">
                  <div className="text-sm">
                    <div className="font-medium text-gray-700">
                      {productor.nombre}
                    </div>
                    <div className="text-gray-500">{productor.apellido}</div>
                  </div>
                </td>
                <td className="py-3 px-6 text-left">{productor.dni}</td>
                <td className="py-3 px-6 text-left">
                  {productor.centropoblado}
                </td>
                <td className="py-3 px-6 text-left">
                  {productor.comunidadsector}
                </td>
                <td className="py-3 px-6 text-center">
                  {productor.observando ? (
                    <span className="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs">
                    Deshabilitado
                  </span> 
                  ) : (
                    <span className="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs">
                    Habilitado
                  </span> 
                  )}
                   
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center">
                    <Link to={`/observados/${productor.id}`}>
                      <div className="w-7 my-auto transform hover:text-teal-500 hover:scale-110">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                      </div>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListadoBeneficiarios;
