import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

const FichaBeneficiario = () => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [edad, setEdad] = useState("");
  const [imagen, setImagen] = useState("");

  const [centroPoblado, setCentroPoblado] = useState("");
  const [comunidadSector, setComunidadSector] = useState("");

  const [brownswiss, setBrownswiss] = useState("");
  const [criollo, setCriollo] = useState("");
  const [holstein, setHolstein] = useState("");
  const [otraRaza, setOtraRaza] = useState("");

  const [corriedale, setCorriedale] = useState("");
  const [hampshire, setHampshire] = useState("");
  const [criollov, setCriollov] = useState("");
  const [blackbelly, setBlackbelly] = useState("");
  const [otras, setOtras] = useState("");

  const [totalHectareas, setTotalHectareas] = useState("");

  const { id } = useParams();

  const getBeneficiarioById = async (id) => {
    const beneficiario = await getDoc(doc(db, "basedsaman", id));
    if (beneficiario.exists()) {
      setNombre(beneficiario.data().nombre);
      setApellido(beneficiario.data().apellido);
      setDni(beneficiario.data().dni);
      setEdad(beneficiario.data().edad);
      setCentroPoblado(beneficiario.data().centropoblado);
      setComunidadSector(beneficiario.data().comunidadsector);

      setBrownswiss(beneficiario.data().ntotalvacunobrownswiss);
      setCriollo(beneficiario.data().ntotalvacunocriollo);
      setHolstein(beneficiario.data().ntotalvacunoholstein);
      setOtraRaza(beneficiario.data().ntotalvacunootraraza);

      setCorriedale(beneficiario.data().totovinocorriedale);
      setHampshire(beneficiario.data().totovinohampshire);
      setCriollov(beneficiario.data().totovinocriollo);
      setBlackbelly(beneficiario.data().totovinoBlackbelly);
      setOtras(beneficiario.data().totovinootras);

      setTotalHectareas(beneficiario.data().totaldehectareas);

      setImagen(beneficiario.data().image);
    } else {
      console.log("El Beneficiario no existe!!!");
    }
  };

  useEffect(() => {
    getBeneficiarioById(id);
  }, [id]);

  const imprimirDocumento = () => {
    window.print();
  };

  const sumarVacuno = () => {
    return Number(brownswiss + criollo + holstein + otraRaza) + " vacunos";
  };

  const sumarOvino = () => {
    return (
      Number(corriedale + hampshire + criollov + blackbelly + otras) + " ovinos"
    );
  };

  return (
    <div className="min-h-screen bg-green-200">
      <h2 className="text-3xl font-black m-auto pt-6 pb-4">
        Ficha {""}
        <span className="text-sky-500">Beneficiario</span>
      </h2>
      <Link
        to="/"
        className="text-sm rounded-md bg-green-600 text-white py-2 px-6 font-black hover:bg-green-500  hover:shadow-lg hover:font-bold print:hidden"
      >
        Regresar
      </Link>

      <section className="py-1 bg-green-200">
        <div className="w-full lg:w-8/12 px-5 mx-auto mt-3 flex justify-end print:hidden">
          <button
            className="bg-black py-2 px-6 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105 font-bold"
            onClick={imprimirDocumento}
          >
            Imprimir
          </button>
        </div>
        <div className="w-full lg:w-8/12 px-4 mx-auto mt-3 mb-10">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0 print:shadow-none print:border-2 print:border-slate-300">
            <div className="rounded-t bg-green-50 px-6 py-6 print:border-b-2 print:border-slate-300">
              <h6 className="text-blueGray-700 text-lg font-bold uppercase">
                Datos Generales del Productor
              </h6>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-3 bg-green-100">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-5">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Nombre
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Apellido
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={apellido}
                      onChange={(e) => setApellido(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      DNI
                    </label>
                    <input
                      type="number"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={dni}
                      onChange={(e) => setDni(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Edad
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={edad}
                      onChange={(e) => setEdad(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Centro Poblado
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={centroPoblado}
                      onChange={(e) => setCentroPoblado(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Comunidad / Sector
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={comunidadSector}
                      onChange={(e) => setComunidadSector(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />
              <h6 className="text-black text-sm mt-3 mb-4 font-bold uppercase">
                Recursos Agropecuarios
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Total de Vacunos
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={sumarVacuno()}
                      onChange={(e) => setBrownswiss(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Total de Ovinos
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={sumarOvino()}
                      onChange={(e) => setCriollo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 print:w-1/2">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Total de Hectáreas
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50 print:border-slate-200"
                      value={totalHectareas + " hectáreas"}
                      onChange={(e) => setHolstein(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300 print:hidden" />
              <h6 className="text-black text-sm mt-3 mb-4 font-bold uppercase print:hidden">
                Foto
              </h6>

              <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow mt-4 text-center print:hidden">
                <img className="rounded-t-lg" src={imagen} alt="Foto" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FichaBeneficiario;
