import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

const BeneficiariosObservados = () => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [niveducacion, setNiveducacion] = useState("");

  const [ncelular, setNcelular] = useState("");
  const [centropoblado, setCentropoblado] = useState("");
  const [comunidadsector, setComunidadsector] = useState("");
  const [chijos, setChijos] = useState("");

  const [observando, setObservando] = useState(false);
  const [descripcionObservar, setDescripcionObservar] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const addBeneficiarioObservado = async (event) => {
    event.preventDefault();
    const beneficiarioRef = doc(db, "basedsaman", id);

    const data = {
      descripcionObservar: descripcionObservar,
      observando: observando,
    };

    await updateDoc(beneficiarioRef, data);
    navigate("/list");
  };

  const getBeneficiarioById = async (id) => {
    const beneficiario = await getDoc(doc(db, "basedsaman", id));
    if (beneficiario.exists()) {
      setNombre(beneficiario.data().nombre);
      setApellido(beneficiario.data().apellido);
      setDni(beneficiario.data().dni);
      setNiveducacion(beneficiario.data().niveducacion);
      setNcelular(beneficiario.data().ncelular);
      setCentropoblado(beneficiario.data().centropoblado);
      setComunidadsector(beneficiario.data().comunidadsector);
      setChijos(beneficiario.data().chijos);
      setDescripcionObservar(beneficiario.data().descripcionObservar);
      setObservando(beneficiario.data().observando || false);
      // console.log(
      //   "Valor de observando desde Firestore:",
      //   beneficiario.data().observando
      // );
    } else {
      console.log("El Beneficiario no existe!!!");
    }
  };

  useEffect(() => {
    const fetchBeneficiario = async () => {
      const beneficiario = await getDoc(doc(db, "basedsaman", id));
      if (beneficiario.exists()) {
        setObservando(beneficiario.data().observando);
      }
    };

    fetchBeneficiario();
  }, [id]);

  // const handleObservarChange = async () => {
  //   const beneficiarioRef = doc(db, "", id);
  //   const data = { observando: !observando };
  //   await updateDoc(beneficiarioRef, data);
  //   setObservando(!observando);
  // };

  useEffect(() => {
    getBeneficiarioById(id);
  }, [id]);

  return (
    <>
      <h2 className="text-3xl font-black m-auto mt-6 mb-3">
        Beneficiarios {""}
        <span className="text-red-500">Observados</span>
      </h2>
      <Link
        to="/list"
        className="text-sm uppercase rounded-md bg-green-600 text-white py-2 px-6 font-black hover:bg-green-500  hover:shadow-lg hover:font-bold"
      >
        Regresar
      </Link>

      <section className=" py-1 bg-green-50 mt-3 ">
        <div className="w-full lg:w-8/12 px-4 mx-auto mt-6 mb-10">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-green-200 px-6 py-6 mb-3">
              <h6 className="text-blueGray-700 text-lg font-bold uppercase">
                Datos Generales del Productor
              </h6>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-5">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Nombre
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Apellido
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50"
                      value={apellido}
                      onChange={(e) => setApellido(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      DNI
                    </label>
                    <input
                      type="number"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50"
                      value={dni}
                      onChange={(e) => setDni(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase font-bold text-xs text-blueGray-600 ">
                      Nivel de educacion
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50"
                      value={niveducacion}
                      onChange={(e) => setNiveducacion(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-black text-sm mt-3 mb-6 font-bold uppercase">
                información de contacto
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Nro. de Celular
                    </label>
                    <input
                      type="tel"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none w-full bg-gray-50"
                      value={ncelular}
                      onChange={(e) => setNcelular(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Centro poblado
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none bg-gray-50"
                      value={centropoblado}
                      onChange={(e) => setCentropoblado(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Comunidad/Sector
                    </label>
                    <input
                      type="text"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none bg-gray-50"
                      value={comunidadsector}
                      onChange={(e) => setComunidadsector(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Cantidad de hijos
                    </label>
                    <input
                      type="number"
                      disabled
                      className="p-2 rounded-md border-2 border-green-600 mt-2 focus:outline-none bg-gray-50"
                      value={chijos}
                      onChange={(e) => setChijos(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="font-sans mt-16 mb-20">
          <div className="relative flex flex-col sm:justify-center items-center bg-green-50">
            <div className="relative sm:max-w-sm w-full">
              <div className="card bg-green-400 shadow-lg  w-full h-full rounded-3xl absolute  transform -rotate-6"></div>
              <div className="card bg-red-400 shadow-lg  w-full h-full rounded-3xl absolute  transform rotate-6"></div>
              <div className="relative w-full rounded-3xl  px-6 py-4 bg-gray-100 shadow-md">
                <label className="block mt-3 text-lg text-gray-700 text-center mb-3">
                  Administrar Usuario {""}
                  <span className="text-red-500 font-bold">Observado</span>
                </label>
                {!observando ? (
                  <div
                    className="mb-3 inline-flex w-full items-center rounded-lg bg-green-200 py-5 px-6 text-base text-green-700"
                    role="alert"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="h-7 w-7"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    Habilitado
                  </div>
                ) : (
                  <div
                    className="mb-3 inline-flex w-full items-center rounded-lg bg-red-200 py-5 px-6 text-base text-red-700"
                    role="alert"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="h-7 w-7"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    Deshabilitado
                  </div>
                )}
                <form className="mt-3" onSubmit={addBeneficiarioObservado}>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={observando}
                      onChange={() => setObservando(!observando)}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-green-500 rounded-full peer peer-focus:ring-4 peer-focus:ring-stone-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-500"></div>
                    <span className="ml-3 text-sm font-bold text-gray-900">
                      <span className="text-green-500 font-bold">
                        No observar
                      </span>{" "}
                      / <span className="text-red-500 font-bold">Observar</span>
                    </span>
                  </label>

                  <div className="flex flex-wrap mt-3">
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full">
                        <label className="block uppercase font-bold text-xs text-blueGray-600">
                          Proporcionar descripción
                        </label>
                        <textarea
                          className="w-full p-2 rounded-md border-2 mt-2 focus:outline-none focus:border-lime-600"
                          placeholder="Escriba aquí una descripción detallada..."
                          required
                          value={descripcionObservar}
                          onChange={(e) =>
                            setDescripcionObservar(e.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="mt-7">
                    <input
                      type="submit"
                      className="bg-green-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                      value="Guardar Cambios"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BeneficiariosObservados;
