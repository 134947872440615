import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import BeneficiariosObservados from "./components/BeneficiariosObservados";
import Create from "./components/Create";
import Edit from "./components/Edit";
import InfraCobertizos from "./components/InfraCobertizos";
import InfraEniles from "./components/InfraEniles";
import Infraestructura from "./components/Infraestructura";
import InfraTernejare from "./components/InfraTernejare";
import ListadoBeneficiarios from "./components/ListadoBeneficiarios";
import Navbar from "./components/Navbar";
import Show from "./components/Show";
import LoginPage from "./page/LoginPage";
import PrivateRoute from "./router/PrivateRoute";
import FichaBeneficiario from "./components/FichaBeneficiario";
import ReporteOvino from "./components/ReporteOvino";
import ReporteVacuno from "./components/ReporteVacuno";
import ReporteTerrenos from "./components/ReporteTerrenos";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navbar />} />
          <Route
            exact
            path="/show"
            element={<PrivateRoute component={Show} />}
          />
          <Route
            exact
            path="/create"
            element={<PrivateRoute component={Create} />}
          />
          <Route
            exact
            path="/edit/:id"
            element={<PrivateRoute component={Edit} />}
          />
          <Route
            exact
            path="/infra"
            element={<PrivateRoute component={Infraestructura} />}
          />

          <Route
            exact
            path="/list"
            element={<PrivateRoute component={ListadoBeneficiarios} />}
          />

          <Route
            exact
            path="/observados/:id"
            element={<PrivateRoute component={BeneficiariosObservados} />}
          />
          <Route
            exact
            path="/cobertizos"
            element={<PrivateRoute component={InfraCobertizos} />}
          />
          <Route
            exact
            path="/terneraje"
            element={<PrivateRoute component={InfraTernejare} />}
          />
          <Route
            exact
            path="/eniles"
            element={<PrivateRoute component={InfraEniles} />}
          />
          <Route
            exact
            path="/ficha/:id"
            element={<PrivateRoute component={FichaBeneficiario} />}
          />
          <Route
            exact
            path="/reporte-ovino"
            element={<PrivateRoute component={ReporteOvino} />}
          />
          <Route
            exact
            path="/reporte-vacuno"
            element={<PrivateRoute component={ReporteVacuno} />}
          />
          <Route
            exact
            path="/reporte-terrenos"
            element={<PrivateRoute component={ReporteTerrenos} />}
          />

          <Route exact path="/login" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
