import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  limit,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import user2 from "../images/user2.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const Show = () => {
  //! hooks
  const [productores, setProductores] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProductores, setFilteredProductores] = useState([]);

  //! DB firestore
  const productoreCollection = collection(db, "basedsaman");

  //! mostrar docs
  const getProductores = async () => {
    // const data = await getDocs(productoreCollection);
    const data = await getDocs(query(productoreCollection, limit(5000)));

    // console.log(data.docs);
    setProductores(data.docs.map((doct) => ({ ...doct.data(), id: doct.id })));
    // console.log(productores);
  };

  //! eliminar doc
  const deleteProduct = async (id) => {
    const productorDoct = doc(db, "basedsaman", id);
    await deleteDoc(productorDoct);
    getProductores();
  };

  //! Alert Sweet 2
  const confirmDelete = (id, nombre) => {
    MySwal.fire({
      title: `¿Eliminar Productor:  ${nombre} ?`,
      text: "¡No querrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "¡sí, bórralo!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(id);
        Swal.fire(
          "Eliminado!",
          "Su archivo ha sido eliminado.",
          "exitosamente"
        );
      }
    });
  };

  //! useEffect
  useEffect(() => {
    getProductores();
  }, []);

  //! filtrar productores según el término de búsqueda
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    // console.log(filteredProductores);
  };

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredProductores(productores);
    } else {
      setFilteredProductores(
        productores.filter(
          (productor) =>
            productor.dni.toString().includes(searchTerm) ||
            (productor.nombre + " " + productor.apellido.toString())
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          // productor.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
          // (productor.apellido && productor.apellido.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );
    }
  }, [searchTerm, productores]);

  //! vista del componente
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-green-100 shadow-md my-10 mx-10">
        <div className="my-5">
          <div className="relative mx-5">
            <input
              value={searchTerm}
              onChange={handleChange}
              type="search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border-2 border-green-600 rounded-lg bg-white  focus:outline-none  focus:border-green-500"
              placeholder="Buscar productor por nombre..."
            />
          </div>
        </div>

        <div className="my-5 ">
          <Link
            to="/create"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-200"
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-sky-100 rounded-md group-hover:bg-opacity-0">
              Agregar Nuevo Productor
            </span>
          </Link>
        </div>

        <table className="w-full border-collapse bg-green-50 text-left text-sm text-gray-600 ">
          <thead className="bg-emerald-200 border-b-2 border-b-sky-500">
            <tr className="uppercase text-gray-700 text-xs leading-normal ">
              <th scope="col" className="px-6 py-4 text-left">
                Nombre y Apellido
              </th>
              <th scope="col" className="px-7 py-5 text-left">
                DNI
              </th>
              <th scope="col" className="px-7 py-5 text-left">
                Edad
              </th>
              <th scope="col" className="px-7 py-5 text-left">
                Centro Poblado
              </th>
              <th scope="col" className="px-7 py-5 text-center">
                Acciones
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-sky-200 border-t border-sky-200">
            {productores.length !== 0 ? (
              filteredProductores.map((productor) => (
                <tr key={productor.id} className="hover:bg-sky-100">
                  <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                    <span className="relative h-10 w-10">
                      <img src={user2} alt="img" />
                    </span>
                    <div className="text-sm">
                      <div className="font-medium text-gray-700">
                        {productor.nombre}
                      </div>
                      <div className="text-gray-400">{productor.apellido}</div>
                    </div>
                  </th>
                  <td className="px-6 py-4">{productor.dni}</td>
                  <td className="px-6 py-4">{productor.edad}</td>
                  <td className="px-6 py-4">{productor.centropoblado}</td>
                  <td className="px-6 py-4 text-center">
                    <div className="flex items-center justify-center">
                      <Link
                        to={`/edit/${productor.id}`}
                        className="inline-block w-4 mr-3 transform text-blue-500 hover:text-blue-700 hover:scale-110"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-8 w-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </Link>

                      <div
                        className="w-4 ml-3 mr-2 transform text-red-500 hover:text-red-700 hover:scale-110 cursor-pointer"
                        onClick={() => {
                          confirmDelete(productor.id, productor.nombre);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-8 w-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </div>

                      <Link
                        to={`/ficha/${productor.id}`}
                        className="inline-block w-4 ml-3 mr-2 transform text-sky-400 hover:text-sky-600 hover:scale-110"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-8 w-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="grid mx-[120%] my-8 w-full place-items-center"
                  role="status"
                >
                  <svg
                    aria-hidden="true"
                    className="inline w-14 h-14 mr-2 text-gray-200 animate-spin fill-red-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Show;
