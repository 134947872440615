const centroPoblados = [
  { id: "CP_CHUCARIPO", nombre: "CP CHUCARIPO" },
  { id: "ZONA_CENTRO", nombre: "ZONA CENTRO" },
  { id: "CP_QUEJON_MOCCO", nombre: "CP QUEJON MOCCO" },
  { id: "CP_MUNI_GRANDE", nombre: "CP MUNI GRANDE" },
  { id: "CP_JASANA_GRANDE", nombre: "CP JASANA GRANDE" },
  { id: "CP_CHACAMARCA", nombre: "CP SAN JUAN DE CHACAMARCA" },
];

export { centroPoblados };
